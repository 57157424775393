import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store'
import ReactGA from 'react-ga4';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Initialize GA4 with your measurement ID
ReactGA.initialize("G-BXTGKB28NF"); 

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// Modified web vitals reporting to include GA
reportWebVitals(metric => {
  ReactGA.event({
    category: 'Web Vitals',
    action: metric.name,
    value: Math.round(metric.value),
    label: metric.id,
    nonInteraction: true,
  });
});
