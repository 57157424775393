import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import styles from "../create-feedback/CreateFeedback.module.scss";

const FeedbackList = ({
  annotations,
  loading,
  editingCommentId,
  editedCommentText,
  setEditingCommentId,
  setEditedCommentText,
  handleUpdateComment,
  handleDeleteClick,
  handleAudioPlay,
  currentTime
}) => {
  const [expandedCommentId, setExpandedCommentId] = useState(null);

  const toggleComment = (id) => {
    console.log("Id here", id);
    setExpandedCommentId((prev) => (prev === id ? null : id)); // Toggle between the clicked ID and null
  };

  const activeCommnet = (annotation) => {
    return (
      currentTime >= annotation.timeRange.startTime &&
      currentTime <= annotation.timeRange.startTime + 0.3
    );
  };

  return (
    <div className={styles.annotations_list}>
      <h4>Feedback List</h4>
      {loading ? (
        <div className={styles.loader}>Loading...</div>
      ) : annotations?.length === 0 ? (
        <div className={styles.no_data}>
          <p>Feedback not found</p>
        </div>
      ) : (
        annotations?.sort((a, b) => {
            const aActive = activeCommnet(a);
            const bActive = activeCommnet(b);

            if (aActive && !bActive) return -1;
            if (!aActive && bActive) return 1;
            return 0;
          })
          .map((annotation) => {
            const isExpanded = expandedCommentId === annotation._id;
            const isEditing = editingCommentId === annotation._id;
            const commentText = annotation.comments[0].body;

            return (
              <div key={annotation.id} className={styles.annotation_item}>
                <div
                  className={styles.annotation_header}
                  style={{
                    backgroundColor: activeCommnet(annotation) ? "yellow" : "#fff",
                    border: activeCommnet(annotation)
                      ? "2px solid green"
                      : "1px solid gray",
                  }}
                >
                  <div className={styles.name}>
                    <p
                      className={styles.time_range}
                      style={{
                        color: activeCommnet(annotation) ? "green" : "gray",
                      }}
                    >
                      {annotation.timeRange.startTime.toFixed(2)}s
                    </p>

                    {isEditing ? (
                      <div className={styles.edit_container}>
                        <textarea
                          value={editedCommentText}
                          onChange={(e) => setEditedCommentText(e.target.value)}
                          className={styles.edit_textarea}
                        />
                        <div className={styles.edit_actions}>
                          <button
                            onClick={() => handleUpdateComment(annotation)}
                            className={styles.save_edit}
                            title="Save"
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ color: "#28a745" }}
                            />
                          </button>
                          <button
                            onClick={() => {
                              setEditingCommentId(null);
                              setEditedCommentText("");
                            }}
                            className={styles.cancel_edit}
                            title="Cancel"
                            >
                            <FontAwesomeIcon
                              icon={faXmark}
                              style={{ color: "#dc3545" }}
                            />
                            </button>
                          </div>
                          </div>
                        ) : (
                          <>
                          {commentText.startsWith("data:audio") ? (
                            <audio
                            controls
                            onPlay={(e) => handleAudioPlay(e.target, annotation)}
                            >
                            <source src={commentText} type="audio/wav" />
                            Your browser does not support the audio element.
                            </audio>
                          ) : (
                            <p
                            className={styles.comment_text}
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              whiteSpace: "pre-line"
                            }}
                            >
                            {(() => {
                              return isExpanded
                                ? commentText
                                : `${commentText.slice(0, 50)}${commentText.length > 50 ? "..." : ""}`;
                            })()}
                          </p>
                        )}
                        {commentText.length > 50 &&
                          !commentText.startsWith("data:audio") && (
                            <button
                              onClick={() => toggleComment(annotation._id)}
                              className={styles.seeMoreButton}
                            >
                              {isExpanded ? "See Less" : "See More"}
                            </button>
                            )}

                          {handleUpdateComment && activeCommnet(annotation) &&
                            <div className={styles.comment_actions}>
                            {!commentText.startsWith("data:audio") && (
                              <button
                              onClick={() => {
                                  setEditingCommentId(annotation._id);
                                  setEditedCommentText(
                                    annotation.comments[0].body
                                  );
                                }}
                                className={styles.edit_button}
                                disabled={
                                  editingCommentId !== null &&
                                  editingCommentId !== annotation._id
                                }
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </button>
                            )}
                            <button
                              onClick={() => handleDeleteClick(annotation)}
                              className={styles.delete_button}
                              disabled={editingCommentId !== null}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        }
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })
      )}
    </div>
  );
};

export default FeedbackList;