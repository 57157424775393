import React, { useState } from "react";
import { Stage, Layer, Rect } from "react-konva";
import styles from "../create-feedback/CreateFeedback.module.scss";

const AnnotationStage = ({ videoDimensions,playerRef, annotations, newAnnotation, setNewAnnotation, drawing, setDrawing, isPaused }) => {
  const handleStartDrawing = (e) => {
    const { x, y } = e.target.getStage().getPointerPosition();
    setNewAnnotation({
      x,
      y,
      width: 0,
      height: 0,
    });
    setDrawing(true);
  };

  const handleDrawing = (e) => {
    if (!drawing) return;

    const { x, y } = e.target.getStage().getPointerPosition();
    setNewAnnotation((prev) => ({
      ...prev,
      width: x - prev?.x,
      height: y - prev?.y,
    }));
  };

  const handleEndDrawing = () => {
    setDrawing(false);
  };
  
  const filteredAnnotations = annotations?.filter((annotation) => {
    const currentTime = playerRef.current?.currentTime(); // Get the current time of the video
    const { startTime, endTime } = annotation.timeRange; // Extract start and end times of the annotation
    return currentTime >= startTime && currentTime <= endTime;
  });

  return (
    <Stage
      width={videoDimensions.width}
      height={videoDimensions.height}
      className={styles.konva_stage}
      onMouseDown={handleStartDrawing}
      onMouseMove={handleDrawing}
      onMouseUp={handleEndDrawing}
      onTouchStart={handleStartDrawing}
      onTouchMove={handleDrawing}
      onTouchEnd={handleEndDrawing}
      style={{
        position: "absolute",
        top: `${videoDimensions.top}px`,
        left: `${videoDimensions.left}px`,
        pointerEvents: isPaused ? "auto" : "none",
        cursor: drawing ? "crosshair" : "default",
        touchAction: "none",
      }}

    >
      <Layer>
        {filteredAnnotations?.map((annotation, index) => {
          const x = annotation.markerDetails.coordinates.x1 * videoDimensions.width;
          const y = annotation.markerDetails.coordinates.y1 * videoDimensions.height;
          const width = (annotation.markerDetails.coordinates.x2 - annotation.markerDetails.coordinates.x1) * videoDimensions.width;
          const height = (annotation.markerDetails.coordinates.y2 - annotation.markerDetails.coordinates.y1) * videoDimensions.height;

          return (
            <Rect
              key={index}
              x={x}
              y={y}
              width={width}
              height={height}
              fill="rgba(255, 255, 0, 0)"
              stroke="yellow"
            />
          );
        })}

        {newAnnotation && (
          <Rect
            x={newAnnotation.x}
            y={newAnnotation.y}
            width={newAnnotation.width}
            height={newAnnotation.height}
            fill="rgba(255, 0, 0, 0.04)"
            stroke="yellow"
          />
        )}
      </Layer>
    </Stage>
  );
};

export default AnnotationStage;