import { useState } from "react";
import './SearchVideoWithTagName.scss'

const SearchVideoWithTagName = ({ setSearchText }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const searchBoxPlaceholder = "Search with tag name...";

    const handleSearch = () => {
 
        setSearchText(searchTerm.trim());
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        
        if (value.trim() === "") {
            setSearchText(""); // This will trigger displaying all videos
        }
    };

    return (
        <div className='search-container'>
            <input
                type="text"
                placeholder={searchBoxPlaceholder}
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    handleInputChange(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                className= 'searchBox'
                
            />
            <button onClick={handleSearch} className="searchButton">
                Search
            </button>
        </div>
    );
};

export default SearchVideoWithTagName;
