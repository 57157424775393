import React, { useEffect } from 'react'
import './About.scss'

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='About_us'>
            <div className='bannar'>
                <div className='container'>
                    <h1 className='heading'>About Us</h1>
                </div>
            </div>
            <div className='About_us_content'>
                <div className='container'>
                    <div className='top_section'>
                        <ul>
                            <li>VISIST.AI is transforming the future of sports training through our pioneering AI-powered platform. We deliver intelligent assessment tools that create value across the entire sports ecosystem:</li>
                            
                            <li>For coaches, we provide data-driven insights that enhance training precision and effectiveness, allowing them to focus on what matters most—developing athletes.</li>
                            
                            <li>For athletes, we offer a personal, lifelong smart video journal that tracks progress, highlights improvements, and creates a comprehensive record of their athletic journey.</li>
                            
                            <li>For academies, our AI-powered platform streamlines coaching workflows, standardizes skill assessments, and enhances talent identification—turning complex processes into seamless experiences.</li>
                            
                            <li>For parents, we deliver engaging, in-depth insights into their child's athletic development, empowering them to make informed decisions about training investments and career pathways.</li>
                            
                            <li>At VISIST.AI, we're not just building technology; we're creating the foundation for more successful athletic journeys—one insight at a time.</li>
                        </ul>
                    </div>
                    <div className='image_section'>
                        <img src='/assets/aboutus-1.png' alt='Sports Analytics' />
                        <img src='/assets/aboutus-2.png' alt='AI Training' />
                        <img src='/assets/aboutus-3.png' alt='Performance Tracking' />
                    </div>
                    <div className='bottom_section'>
                          <h1>VISIST AI TECHNOLOGIES PRIVATE LIMITED</h1>
                          <p>
                            62/2-1 ,8th Main, 132, Embassy Heritage, Malleswaram, Bengaluru 560055, INDIA.
                          </p>
                          <p>
                            CIN: U63119KA2024PTC187267, GST:29AAKCV0945N1ZM
                          </p>
                     </div>
                    <div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About