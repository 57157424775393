import React, { useEffect, useState } from 'react'
import './VideoTagsSelectContainer.scss'
import VideoTagSelect from '../VideoTagSelect/VideoTagSelect'
import { GET_ALL_TAGS} from '../../../config/endpoints'
import axios from 'axios'

const VideoTagsSelectContainer = ({ setSelectedTagListInParent }) => {

   const [tagsFetchedSuccessfull, setTagsFetchedSuccessfull] = useState(true);
   const tagsFetchedFailureMsg = "Unable to fetch tags";
   const [tagList, setTagList] = useState([]);
   const [selectedTagList, setSelectedTagList] = useState([]);
   const jwtToken = localStorage.getItem("BQS_TOKEN");

  

    useEffect(() => {
  
           getAllTags();
       }, []);

    
    useEffect(() => {
        setSelectedTagListInParent(selectedTagList);
        
        
    }, [selectedTagList]);

   const getAllTags = async () => {
    try {
        const response = await axios.get(GET_ALL_TAGS, {
            headers: {
                "Authorization": `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            setTagList(response.data.data);
        } else {

            setTagsFetchedSuccessfull(false);
        }
    } catch (err) {
            setTagsFetchedSuccessfull(false);
    }
  };



  const addTagsToSelectedTagList = (selectedTag) => {
    
        selectedTag = JSON.parse(selectedTag);
        if(!selectedTagList.some(tag => tag._id === selectedTag._id))
            setSelectedTagList([...selectedTagList, selectedTag]);
        
  };

  const removeTagFromSelectedTagList = (tagId) => {
    setSelectedTagList(selectedTagList.filter(tag => tag._id !== tagId));
  };

  return (
    

      <div className='video-tags-select-container'>
            { !tagsFetchedSuccessfull ? tagsFetchedFailureMsg: <div></div>}
            <div className='tagListDropdown'>
                <select
                    id="tags"
                    defaultValue=""
                    onChange={(e) => addTagsToSelectedTagList(e.target.value)}
                >
                    <option value="" disabled>Select Tags</option>
                    {tagList.map((tag) => (
                        <option key={tag._id} value={JSON.stringify(tag)}>
                            {tag.tagName}
                        </option>
                    ))}
                </select>
            </div>


                <div>
                    <div className='video-tags'>
                    {selectedTagList.map((tagDetails, index) => 
                        (<VideoTagSelect tagDetails={tagDetails} index={index} 
                          sendTagForRemoval={removeTagFromSelectedTagList}
                          />)

                    )}
                    </div>
                </div> 

        </div>
   
  );
};

export default VideoTagsSelectContainer;
