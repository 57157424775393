import report_image from './blog2Img.png';
import image from './image.png';
import gripTech from './gripTech.png'
import OptimalPoint from './OptimalPoint.png'
import Positioning from './Positioning.png'
import HitTheSmash1 from './hitTheSmash1.png'
import HitTheSmash2 from './hitTheSmash2.png'
import relaxationFocus from './relaxationFocus.png'
import rotationSwing from './rotationSwing.png'
import powerOfVideo from './powerOfVideoAna.png'
import blog3 from './blog3Img.png';
import dirtpathstoglobal from './dirtpathstoglobal.jpg';
import multisports from './multisports.jpg'

export const blogDetails = [

    {
        id: 1,
        head: "From Dirt Paths to Global Stages",
        date: "5 March 2025",
        name: [
            {
                type: "The Impossible Becomes Possible",
                description: [
                    { para: "In the heart of India's villages, a revolution is brewing. It's not just about talent anymore—it's about how technology, passion, and relentless determination are transforming ordinary dreams into extraordinary achievements." },
                    { para: "Every great journey begins with a single step—often on an uneven, dusty track, with borrowed dreams and limited resources. But today's grassroots athletes are proving that boundaries are meant to be shattered." }
                ]
            },
            {
                type: "🏆 The Game Changers: Stories That Inspire",
                description: [
                    { para: "1. Neeraj Chopra: Precision Beyond Tradition" },
                    { para: "Imagine a young boy from Haryana's rural landscape, throwing javelins with makeshift equipment. Today, Neeraj Chopra isn't just an athlete; he's a movement. High-speed cameras and biomechanical analysis transformed his raw talent into Olympic gold. A 2-degree angle adjustment became the difference between potential and perfection." },
                    { para: "Key Insight: Technology doesn't replace hard work—it amplifies it." },
                    { para: "2. Mary Kom: Fighting Beyond Boundaries" },
                    { para: "From Manipur's challenging terrains, Mary Kom didn't just box—she revolutionized women's sports. Video analysis tools became her strategic weapon, breaking down opponents' patterns with scientific precision. Six World Championships later, she's more than a boxer—she's an icon of resilience." },
                    { para: "Technology's Role: Converting instinct into strategic brilliance." },
                    { para: "3. Dipa Karmakar: Defying Gravity's Limitations" },
                    { para: "Tripura's gymnastics marvel started with scooter parts as training equipment. Smartphone videos and advanced motion tracking transformed her technique. The dangerous Produnova vault wasn't just a move—it was a statement that limitations are often just perceptions." },
                    { para: "Breakthrough Moment: When innovative technology meets unyielding spirit." },
                    { para: "4. Dutee Chand: Running Against All Odds" },
                    { para: "From rural Odisha's dirt tracks to international sprinting stages, Dutee's journey is powered by laser timing systems and advanced stride analysis. GPS tracking and heart rate monitoring turned her raw speed into calculated performance." },
                    { para: "Empowerment Mantra: Data doesn't discriminate; talent does." },
                    { para: "5. Avinash Sable: Calculating Success" },
                    { para: "Transforming from a 6km school run to steeplechase glory, Avinash proves that smart training trumps traditional methods. VO2 max testing and lactate threshold monitoring turned his endurance into a science." },
                    { para: "Performance Formula: Technology + Technique = Excellence" }
                ]
            },
            {
                type: "The Technology Toolkit Revolutionizing Sports",
                subType: "1. Wearable Performance Trackers",
                lists: [
                    "Real-time biomechanical data",
                    "Personalized training insights"
                ]
            },
            {
                subType: "2. AI-Powered Movement Analysis",
                lists: [
                    "Precise technique refinement",
                    "Injury prevention strategies"
                ]
            },
            {
                subType: "3. Virtual Reality Training",
                lists: [
                    "Mental preparation",
                    "Tactical simulation"
                ]
            },
            {
                subType: "4. Nutrition and Recovery Apps",
                lists: [
                    "Customized diet plans",
                    "Recovery optimization"
                ]
            },
            {
                type: "Beyond Technology: The Human Spirit",
                subType: "While technology provides tools, the real magic lies in:",
                lists: [
                    "Unwavering determination",
                    "Supportive ecosystems",
                    "Belief in oneself",
                    "Continuous learning"
                ]
            },
            {
                type: "A Call to Aspiring Athletes",
                description: [
                    { para: "Your limitations are often just unexplored opportunities. Embrace technology, seek knowledge, and remember: every global champion started exactly where you are right now." },
                    { para: "🌟 Your Journey Matters" },
                    { para: "Whether you're training in a village gymnasium or dreaming on a local playground, your potential is limitless. Technology is your ally, but your spirit is your true superpower." },
                    { para: "Stay Curious. Stay Hungry. Keep Pushing Boundaries." }
                ]
            },
        ]
    },

    {
        id: 2, head: "Perfect Smash in Badminton!",
        date: "30 May 2024",
        name: [
            {
                type: "",
                description: [
                    {
                        para: "The badminton smash is a powerful downward attacking shot designed to end a rally or elicit a weak response from opponents. The world record for a smash is a staggering 565 km/hour!",

                    },
                    {
                        para: "To execute a potent badminton smash, position yourself slightly behind the shuttle, grip the racket at the end of the handle, and use a dynamic rotation of your hips, torso, and shoulder. This generates swift racket head speed and propels your body weight forward into the shuttle, maximizing impact. Sounds simple?"
                    },
                    {
                        para: "Let’s dive into all the essential details you need to master the badminton smash!"

                    }
                ]
            },
            {
                type: "Step 1: Grip Technique",
                description: [
                    { para: "Hold the racket with a forehand grip at the end of the handle to create a long lever, which is essential for generating power. It’s crucial for beginners to master this grip as it forms the foundation of a powerful smash." },
                ],
                images: [
                    {
                        img: gripTech
                    }
                ]
            },
            {
                type: "Step 2: Positioning",
                description: [
                    { para: "Ensure you are correctly positioned behind the shuttlecock; ideally, you should be about 0.5 meters behind. This allows for optimal body rotation into the shot, generating the maximum power possible." },
                ],
                images: [
                    {
                        img: Positioning
                    }
                ]
            },
            {
                type: "Step 3: Prepare to hit the smash",
                description: [
                    { para: "Your stance should be sideways relative to the net, with your racket arm bent at a 90-degree angle and your non-racket arm pointing up for balance and timing. This setup helps in maintaining the deception across various overhead shots like smashes, clears, and drops." },
                ],
                images: [
                    {
                        img: HitTheSmash1
                    },
                    {
                        img: HitTheSmash2
                    }
                ]
            },
            {
                type: "Step 4: Relaxation and Focus",
                description: [
                    { para: "Keep your grip loose and body relaxed until the very last second before the shuttlecock contact. This relaxation allows for a sudden burst of speed in the racket head when tightened at the moment of impact." },
                ],
                images: [
                    {
                        img: relaxationFocus
                    },

                ]
            },
            {
                type: "Step 5: Rotation and Swing",
                description: [
                    { para: "Rotate your hips and torso to initiate the power sequence. Your shoulder and elbow should be pulled back, creating a whipping motion that culminates in a powerful smash. This rotation is critical as it channels the strength from your lower body through to your racket." },
                ],
                images: [
                    {
                        img: rotationSwing
                    },

                ]
            },
            {
                type: "Step 6: Delay and Snap",
                description: [
                    { para: "As your body rotates, keep your elbow pulled back to increase the tension across your chest muscles. Releasing this tension suddenly adds additional force to the smash, increasing both speed and power." },
                ]
            },
            {
                type: "Optimal Contact Point",
                description: [
                    { para: "Research indicates the most effective smashes are not made at the center of the racket but slightly off-center. This finding emphasizes the importance of precise racket orientation at the point of impact." },
                ],
                images: [
                    {
                        img: OptimalPoint
                    },

                ],
                subDescription: [
                    {
                        para: "For more such tips and tricks please bookmark this blog page and come back to it regularly!"
                    }
                ]
            },
            {
                type: "Ref (Research papers)",
                links: [
                    {
                        a: "https://development.bwfbadminton.com/wp-content/uploads/2023/01/FINAL-ITB_Adiprawita.pdf"
                    },
                    {
                        a: "https://development.bwfbadminton.com/wp-content/uploads/2016/08/Final_Abdurrahman-et-al._Loughborough-University.pdf"
                    },
                    {
                        a: "https://ojs.ub.uni-konstanz.de/cpa/article/view/2521"
                    },
                    {
                        a: "https://ojs.ub.uni-konstanz.de/cpa/article/view/2521"
                    },
                    {
                        a: "https://www.tandfonline.com/doi/epdf/10.1080/02640414.2020.1792132?needAccess=true"
                    },
                ]
            },
        ]
    },
    {
        id: 3,
        head: "The Power of Video Analysis in Sports",
        date: "2 June 2024",

        name: [
            {
                description: [
                    { para: "Video analysis is a transformative tool for athletes and coaches aiming to enhance performance and strategy. By reviewing game footage, you can spot strengths and weaknesses, make informed decisions, and boost your overall game." },
                    {
                        para: "Video analysis involves recording games and practices, then breaking down and reviewing the footage to evaluate performance. It helps uncover valuable insights that can lead to improved techniques and strategies."
                    },
                    {
                        para: "Capture: Record your games and practices using a camera or smartphone. Automated solutions can simplify this process by capturing and uploading video automatically."
                    },
                    {
                        para: "Breakdown: Analyze your footage by tagging specific moments for further review. The goal is to connect data points with the video to create a visual report for easy analysis."
                    },
                    {
                        para: "Analyze: Study the video and data together with your coach to make important observations that help you improve."
                    }
                ]
            },

            {
                type: "Benefits of Video Analysis",
                lists: [
                    "Performance Feedback: Video analysis provides immediate and accurate feedback. Athletes & Coaches can pinpoint areas for improvement, correct mistakes, and highlight successful plays. This helps athletes understand their strengths and weaknesses better.",
                    "Skill Development: By examining video footage, athletes can identify subtle movements and techniques to improve their skills. It empowers them to take control of their development and make informed adjustments.",
                    "Tactical Insight: Coaches can review footage to analyze game tactics and strategies. This helps in understanding both your’s and the opponent’s play, allowing for better decision-making and strategic planning.",
                    "Injury Prevention: Video analysis can help spot risky movements that might lead to injuries. By correcting these techniques, coaches can reduce the likelihood of injuries and ensure athletes maintain proper form.",
                    "Tracking Progress: Regularly recording and reviewing performance helps track progress over time. It motivates athletes by showing tangible improvements and highlights areas that need more work.",
                    "Conscious and Aware Practice: Reviewing video footage promotes more conscious and aware practice. Athletes can see exactly what needs improvement and focus on those areas during training. This targeted approach is often the fastest and most effective way to enhance performance."


                ]
            },
            {
                type: "Embrace Video Analysis",
                description: [
                    { para: "Success in sports isn’t just about talent; it’s about using every available resource to refine your skills and strategies and it’s built on rigorous process. Video analysis gives athletes and coaches a detailed and objective view of performance, helping athletes reach their full potential. By integrating video analysis into your routine, you can make informed decisions, improve continuously, and achieve greater success on the field." },
                ]
            },
        ]
    },
    {
        id: 4,
        head: "Video Analysis in Performance Sports",
        date: "28 June 2024",
        name: [
            {
                description: [
                    { para: "Video analysis has become an integral tool for sports coaches and athletes to enhance performance. Here are four key ways in which it is used, along with essential insights and examples from racket sports:" }
                ]
            },
            {
                type: "Technical Analysis",
                subType: "Essential Insights:",
                lists: ["Refining Techniques: Video analysis allows athletes to see their movements in slow motion, pinpointing flaws in posture, footwork, and execution.", "Comparing with the Best: Athletes can compare their performance with elite players to understand what they need to improve."]
            },
            {
                subType: "Examples in Racket Sports:",
                lists: ["Novak Djokovic: Djokovic’s coaching team, led by Goran Ivanišević, uses video analysis to refine his serve mechanics and groundstrokes to maintain his top form.", "Viktor Axelsen: Axelsen and his coach, Kenneth Jonassen, leverage video analysis to enhance his footwork and optimize his smash technique."]
            },
            {
                type: "Tactical Analysis",
                subType: "Essential Insights:",
                lists: ["Strategy Development: Coaches can assess how athletes respond to various in-game scenarios and develop strategies accordingly.", "Opponent Analysis: Studying opponents’ patterns, strengths, and weaknesses to formulate effective game plans."]
            },
            {
                subType: "Examples in Racket Sports:",
                lists: [
                    "Ma Long: The Chinese table tennis team, including Ma Long, extensively uses video analysis to study opponents and develop match strategies, often reviewing serve and return patterns.",
                    "Ali Farag: Farag, a top squash player, works with his coaching team to analyze match footage to identify patterns and formulate tactical responses for different opponents."

                ]
            },

            {
                type: "Performance Tracking",
                subType: "Essential Insights:",
                lists: [
                    "Progress Monitoring: Continuous video analysis helps in tracking the progress over time, providing a visual record of improvement or decline.",
                    "Objective Feedback: Video provides objective data that can validate the effectiveness of training programs."
                ]
            },
            {
                subType: "Examples in Racket Sports:",
                lists: [
                    "Serena Williams: Williams has been known to use video analysis to track her baseline performance and monitor changes in her game, particularly focusing on her movement and shot precision.",
                    "Carolina Marin: Carolina's coaching team is known to do video analysis and correct technical and tactical errors. They also use video analysis to understand their opponents strategy and patterns of play."
                ]
            },
            {
                type: "Mental Preparation",
                subType: "Essential Insights:",
                lists: [
                    "Visualization and Mental Rehearsal: Athletes can use video analysis to visualize successful performances and mentally rehearse critical points.",
                    "Confidence Building: Reviewing past successes and well-executed plays can boost an athlete’s confidence before competitions."
                ]
            },
            {
                subType: "Examples in Racket Sports:",
                lists: [
                    "Ding Ning: Ding Ning has used video analysis to mentally prepare by watching her winning matches, reinforcing successful techniques and strategies before competitions.",
                    "Nour El Sherbini: Sherbini uses video analysis to mentally prepare for critical moments in matches, watching footage of her previous performances to build confidence and strategy."
                ]
            },
            {
                type: "Why These Insights are Essential",
                lists: [
                    "Enhanced Precision and Accuracy: Technical analysis ensures that athletes can perform movements with greater precision, reducing the risk of injury and improving overall efficiency.",
                    "Strategic Advantage: Tactical analysis provides a competitive edge by allowing athletes and coaches to outsmart opponents.",
                    "Consistent Improvement: Performance tracking ensures that training regimens are effective and adjustments can be made in real-time.",
                    "Psychological Edge: Mental preparation through video analysis enhances focus and confidence, crucial for peak performance under pressure."
                ]
            },
            {
                type: "Conclusion",
                description: [
                    { para: "Video analysis in racket sports, such as tennis, badminton, table tennis, and squash, offers substantial benefits by refining techniques, developing strategies, tracking performance, and preparing mentally. These insights lead to well-rounded athletes capable of continuous improvement and superior performance in their sporting careers." }
                ]
            }
        ]
    }

];

export const recent_blogs = [
    { id: 1, head: "From Dirt Paths to Global Stages", description: "In the heart of India's villages, a revolution is brewing. It's not just about talent anymore—it's about how....", img: multisports },
    { id: 2, head: "Perfect Smash in Badminton!", description: "The badminton smash is a powerful downward attacking shot designed to end a rally or elicit a weak....", img: gripTech },
    { id: 3, head: "THE POWER OF VIDEO ANALYSIS IN SPORTS", description: "Video analysis is a transformative tool for athletes and coaches aiming to enhance performance and strategy....", img: powerOfVideo },
    { id: 4, head: "Video Analysis in Performance Sports", description: "Video analysis has become an integral tool for sports coaches and athletes to enhance performance. Here are four....", img: blog3 },
];