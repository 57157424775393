import React from "react";
import VideoTagsContainer from '../../Tags/VideoTagsContainer/VideoTagsContainer';
import './GameVideoDetails.scss'

const GameVideoDetails = ({ videoTagList }) => {

  return (
    <div className="game-video-details">
        <VideoTagsContainer videoTagList={videoTagList}/>
    </div>
   
  );
};

export default GameVideoDetails;
