import React, { useRef, useEffect, useState, useCallback } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-markers/dist/videojs.markers.css";
import "videojs-markers";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { isStarted_GetFetchVideoContent, success_GetFetchVideoContent, error_GetFetchVideoContent } from "../../../redux/reducers/videoSlice";
import styles from "../create-feedback/CreateFeedback.module.scss";


const VideoPlayer = ({ videoId, env, currentTime, videoLoaded, videoContent, annotations, setAnnotations, setLoading, setVideoLoaded, setVideoKey, setFrameRate, setCurrentTime, setVideoDimensions, thumbnailUrl, playerRef, videoRef, setEditingCommentId, handlePauseVideo, handlePlayVideo, handleVideoEnd, standAloneFeedbacks, setEditedCommentText, frameRate, isPaused, addStandaloneFeedback, getCoachFeedback, videoKey }) => {
  const dispatch = useDispatch();
  const jwtToken = localStorage.getItem("BQS_TOKEN");
  

  const playerMarkers = (annotations) => {
    console.log("playerMakers-----------------", annotations);
    if (!playerRef.current) {
      console.error("Player instance is not available.");
      return;
    }

    const markersToAdd = annotations.map((annotation) => {
      return {
        time: annotation.timeRange.startTime, // Directly use start time from annotation
        text: annotation?.comments?.[0]?.body.startsWith("data:audio") ? "Audio" : annotation?.comments?.[0]?.body || "Annotation", // Default text if no comment exists
      };
    });
    playerRef.current.markers({
      markers: markersToAdd,
      markerTip: {
        display: true,
        text: (marker) => marker.text, // Show annotation text on hover
      },
      markerStyle: {
        width: "5px",
        height: "20px",
        backgroundColor: "red",
        cursor: "pointer",
        borderRadius: "1px",
        bottom: "-8px",
      },
      onMarkerClick: (marker) => {
        // Seek to the marker's time when clicked
        console.log("Marker clicked at time:", marker.time, currentTime);
      },
      onMarkerReached: (marker) => {
        const time = playerRef.current.currentTime();
        console.log(
          `Reached marker at time: ${marker.time} with text: ${time}`
        );
      },
    });
  };

  useEffect(() => {
    try {
      if (playerRef.current) {
        if (annotations?.length > 0) {
          console.log("Initializing markers with annotations:", annotations);
          playerMarkers(annotations); // Initialize markers with annotations
        } else {
          // playerMarkers(annotations);
          console.warn("anotations--------play", annotations);

          playerMarkers([]);
        }
      } else {
        console.warn("Player instance is not available.");
      }
    } catch (error) {
      console.error("Error while initializing markers:", error.message);
    }
  }, [annotations]);

  const updateDimensions = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const containerWidth = video.offsetWidth;
      const containerHeight = video.offsetHeight;

      const videoAspectRatio = video.videoWidth / video.videoHeight;
      const containerAspectRatio = containerWidth / containerHeight;

      let displayWidth, displayHeight;
      let top = 0;
      let left = 0;

      if (containerAspectRatio > videoAspectRatio) {
        // Container is wider than the video aspect ratio
        displayHeight = containerHeight;
        displayWidth = videoAspectRatio * displayHeight;

        // Center the video horizontally
        left = (containerWidth - displayWidth) / 2;
      } else {
        // Container is taller than the video aspect ratio
        displayWidth = containerWidth;
        displayHeight = displayWidth / videoAspectRatio;

        // Center the video vertically
        top = (containerHeight - displayHeight) / 2;
      }

      setVideoDimensions && setVideoDimensions({
        width: displayWidth,
        height: displayHeight,
        top: top,
        left: left,
      });
    }
  };

  const loadVideo = useCallback(() => {
    // Check if the video reference and `videoLoaded` are ready
    if (videoRef.current && !playerRef.current && videoLoaded) {
      try {
        const videoUrl =
          env === "standalone"
            ? "/video_js.mp4"
            : videoContent?.videoContentUrl;
        const fileType = videoUrl.split(".").pop().toLowerCase();
        const mimeType = fileType === "mov" ? "video/quicktime" : "video/mp4";
        const player = videojs(videoRef.current, {
          controls: true,
          preload: "auto",
          playbackRates: [0.5, 1, 1.5, 2],
          poster: thumbnailUrl,
          sources: [{ src: videoUrl, type: mimeType }],
          controlBar: {
            stayActive: true,
            fullscreenToggle: false,
            pictureInPictureToggle: false,
          },
          userActions: {
            doubleClick: false,
            hotkeys: false,
          },
          // responsive: true,
          // fluid: true,
          inactivityTimeout: 0,
          playsinline: true,
        });

        playerRef.current = player;
        player.on("loadeddata", () => {
          const videoWidth = player.videoWidth();
          const videoHeight = player.videoHeight();
          const videoRatio = videoWidth / videoHeight;
          console.log(`Video Ratio: ${videoRatio.toFixed(2)}`);
          setFrameRate(videoRatio);
          // Attempt to detect frame rate (if metadata or API supports it)
          let detectedFps = 30; // Default to 30 FPS

          try {
            const videoTrack = player?.tech_?.el()?.videoTracks?.[0];
            if (videoTrack && videoTrack.frameRate) {
              detectedFps = videoTrack.frameRate; // Use detected frame rate
            } else {
              setFrameRate(detectedFps);
            }
          } catch (error) {
            console.warn(
              "Failed to detect frame rate. Using default 30 FPS.",
              error
            );
          }

          // Update state with the detected frame rate
          console.log(`Frame rate set to: ${detectedFps} FPS`);
        });

        player.on("timeupdate", () => {
          if (playerRef.current) {
            const currentTime = playerRef.current.currentTime();
            setCurrentTime(currentTime);
          }
        });

        player.on("play", () => {
          console.log("Video is play");
        });
        player.on("pause", () => {
          console.log("Video is pause");
          console.log(
            "currentTimeRef.current===",
            playerRef.current.currentTime()
          );
        });

        player.on("canplay", () => {
          setLoading(false);
        });

        window.addEventListener("resize", updateDimensions);
        updateDimensions();

        player.on("error", () => {
          const error = player.error();
          let errorMessage = "An unknown error occurred.";
          switch (error.code) {
            case 2:
              errorMessage = "Network error: Unable to load the video.";
              break;
            case 4:
              errorMessage =
                fileType === "mov"
                  ? "Unsupported format: .mov files may not be supported in this browser."
                  : "Unsupported format: The video type is not compatible.";
              break;
          }
          console.error(errorMessage);
          toast.error(errorMessage);
        });
      } catch (initializationError) {
        console.error(
          "Error initializing Video.js player:",
          initializationError
        );
        toast.error(
          "Failed to initialize the video player. Please contact support."
        );
      }
    } else {
      console.warn(
        "Video reference is not available. Skipping player initialization."
      );
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      window.removeEventListener("resize", updateDimensions); // Clean up dimensions listener
    };
  }, [videoLoaded, videoContent?.videoContentUrl]);

  useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;
      const checkInterval = 1; // Time interval in milliseconds
      const stopAtAnnotation = () => {
        const currentTime = player?.currentTime(); // Get the current time in seconds
        const frameRates = frameRate || 30; // Default to 30 FPS
        const frameDuration = 1 / frameRates; // Time per frame
        const tolerance = frameDuration / 2; // Use half a frame duration for tighter precision

        // Create a new array to hold updated annotations
        let updatedAnnotations = [...annotations];
        let needsUpdate = false;

        // Reset processed annotations if video is rewound
        updatedAnnotations = updatedAnnotations.map((annotation) => {
          if (
            annotation.processed &&
            currentTime < annotation.timeRange.startTime
          ) {
            needsUpdate = true;
            return { ...annotation, processed: false };
          }
          return annotation;
        });

        // Find the annotation that matches the current time range with tighter precision
        const matchingAnnotation = updatedAnnotations.find(
          (annotation) =>
            annotation?.timeRange?.startTime &&
            !annotation.processed &&
            Math.abs(annotation.timeRange.startTime - currentTime) <= tolerance
        );

        if (matchingAnnotation) {
          player.pause(); // Pause when annotation is found

          // Update the matching annotation's processed status
          updatedAnnotations = updatedAnnotations.map((annotation) => {
            if (annotation === matchingAnnotation) {
              needsUpdate = true;
              return { ...annotation, processed: true };
            }
            return annotation;
          });

          if (!isPaused) {
            // If the player is playing, seek to the annotation time
            player.currentTime(matchingAnnotation.timeRange.startTime); // Seek accurately
          }

          console.log(
            `Paused accurately at annotation startTime: ${matchingAnnotation.timeRange.startTime.toFixed(
              4
            )}, currentTime: ${player.currentTime().toFixed(4)}`
          );
        }

        // Update annotations state if changes were made
        if (needsUpdate) {
          setAnnotations(updatedAnnotations);

          // If in standalone mode, update the Redux store
          if (env === "standalone") {
            const standAloneFeedback = {
              _id: videoId,
              feedback: updatedAnnotations,
            };
            dispatch(addStandaloneFeedback(standAloneFeedback));
          }
        }
      };

      // Set an interval to check every millisecond
      const intervalId = setInterval(stopAtAnnotation, checkInterval);

      // Cleanup the interval when the component unmounts or dependencies change
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [annotations, frameRate, isPaused, env, videoId, dispatch]);

  useEffect(() => {
    const fetchAnnotations = async () => {
      if (!getCoachFeedback) return;
      try {
        const response = await getCoachFeedback(jwtToken, videoId);
        const data = response.data;

        if (!Array.isArray(data)) {
          throw new Error("Invalid data format received from the server.");
        }

        console.log("Fetched annotation data:", data);
        setAnnotations(data);
        loadVideo();
      } catch (error) {
        console.error("Error fetching annotations:", error.message);
        toast.error(
          error.message ||
          "An unexpected error occurred while fetching annotations."
        );
      }
    };

    const initializeStandaloneData = () => {
      const standaloneVideoFeedback = standAloneFeedbacks.find(
        (feedback) => feedback._id === videoId
      );
      const feedbackData = standaloneVideoFeedback
        ? [...standaloneVideoFeedback.feedback]
        : [];
      setAnnotations(feedbackData);
      loadVideo();
    };
    if (env === "standalone") {
      initializeStandaloneData();
    } else {
      fetchAnnotations();
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [env, jwtToken, videoId, loadVideo]);

  useEffect(() => {
    if (env === "standalone" && videoId) {
      const standaloneVideoFeedback = standAloneFeedbacks.find(
        (feedback) => feedback._id === videoId
      );
      const feedbackData = standaloneVideoFeedback
        ? [...standaloneVideoFeedback.feedback]
        : [];
      setAnnotations(feedbackData);
    }
  }, [env, videoId, standAloneFeedbacks]);

  useEffect(() => {
    const updateDimensions = () => {
      if (videoRef.current) {
        const video = videoRef.current;
        const containerWidth = video.offsetWidth;
        const containerHeight = video.offsetHeight;

        const videoAspectRatio = video.videoWidth / video.videoHeight;
        const containerAspectRatio = containerWidth / containerHeight;

        let displayWidth, displayHeight;
        let top = 0;
        let left = 0;

        if (containerAspectRatio > videoAspectRatio) {
          // Container is wider than the video aspect ratio
          displayHeight = containerHeight;
          displayWidth = videoAspectRatio * displayHeight;

          // Center the video horizontally
          left = (containerWidth - displayWidth) / 2;
        } else {
          // Container is taller than the video aspect ratio
          displayWidth = containerWidth;
          displayHeight = displayWidth / videoAspectRatio;

          // Center the video vertically
          top = (containerHeight - displayHeight) / 2;
        }

        setVideoDimensions && setVideoDimensions({
          width: displayWidth,
          height: displayHeight,
          top: top,
          left: left,
        });
      }
    };

    const handleLoadedMetadata = () => {
      updateDimensions();
    };

    const currentVideoRef = videoRef.current;

    if (currentVideoRef) {
      currentVideoRef.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    return () => {
      if (currentVideoRef) {
        currentVideoRef.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      }
    };
  }, []);
;

  return (
    <>
    <div className={styles.video_container}>
      {!videoLoaded && (
        <div className={styles.loader}>
          <div className={styles.spinner}></div>
        </div>
      )}
      <div className={styles.video_controls}>
        <video
          key={videoKey}
          ref={videoRef}
          onPause={handlePauseVideo}
          onPlay={handlePlayVideo}
          onEnded={handleVideoEnd}
          className="video-js"
          onLoadedMetadata={() => {
            setVideoDimensions && setVideoDimensions({
              width: videoRef.current.offsetWidth,
              height: videoRef.current.offsetHeight,
            });
            updateDimensions();
          }}
          poster={thumbnailUrl}
        />
      </div>
    </div>
    </>
  );
};

export default VideoPlayer;