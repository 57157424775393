import React, { useEffect, useState } from 'react';
import styles from './GameReports.module.scss';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Pagination, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { deleteGamePost, getAllPostCounts, getFetchGamePostFileThumbnail, getVideos, markGamePostFav } from '../../../redux/network/videoApi';
import { useLocation, useNavigate } from 'react-router-dom';
import FullScreenDialog from '../../VideoPlayerModel/VideoPlayerModel';
import thumbnail from './thumbnail.jpg';
import { deleteVideoPost, error_GetFetchVideoContent, isStarted_GetFetchVideoContent, success_GetFetchVideoContent, toggleFavVideoPost } from '../../../redux/reducers/videoSlice';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { setAlertMessage, setAlertType } from '../../../redux/reducers/alertSlice';
import { getUserProfile } from '../../../redux/network/userApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import { GET_POST_CONTENT } from '../../../config/endpoints';
import CustomDropdown from '../../CustomeDropdown/CustomDropdown';
import CoachFeedback from '../../CoachFlow/CoachFeedback';
import CloseIcon from '@mui/icons-material/Close';
import SearchVideoWithTagName from "../../SearchBoxes/SearchVideoWithTagName";

const extractTitle = (videoPath) => {
    if (!videoPath) return "Unknown Title";  
    const filename = videoPath.split('/').pop().replace(/\.[^/.]+$/, ""); 
    return filename;
};

const truncateTitle = (title, maxWords = 6) => {
    if (!title) return "";
    const words = title.split(" ");
    return words.length > maxWords ? words.slice(0, maxWords).join(" ") + " ..." : title;
};

const dataFormatter = (dateString) => {
    const inputDate = new Date(dateString);

    const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ];

    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    return `${day} ${monthNames[monthIndex]} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

// Helper function to calculate the start and end of time ranges
const getTimeRange = (range) => {
    const now = new Date();
    let start, end;
    switch (range) {
        case 'lastOneWeek':
            start = new Date(now.setDate(now.getDate() - 7));
            end = new Date();
            break;
        case 'lastMonth':
            start = new Date(now.setMonth(now.getMonth() - 1));
            end = new Date();
            break;
        case 'last3Months':
            start = new Date(now.setMonth(now.getMonth() - 3));
            end = new Date();
            break;
        default:
            start = null;
            end = null;
    }
    return { start: start?.getTime(), end: end?.getTime() };
}

const dropdownOptions = [
    // { value: 'default', label: 'Default' },
    // { value: 'created', label: 'Uploaded' },
    // { value: 'reviewRequested', label: 'Requested' },
    { value: 'reviewed', label: 'Completed' },
    { value: 'favourites', label: 'Favourites' },
];

const GameReports = () => {
    const env = process.env.REACT_APP_ENV;
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const jwtToken = localStorage.getItem("BQS_TOKEN");
    const userId = localStorage.getItem("BQS__ID");
    const { isStarted, isError, error, data } = useSelector(state => state.video.video);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);

    let filteredVideos = [];
    if (env === "standalone") {
        filteredVideos = data.filter(video => video.state === "reviewed"); // Update the outer variable
        console.log("filteredVideos", filteredVideos); // This should now reflect correctly
    }


    const postCountData = useSelector(state => state.video.videosCount);
    const userData = useSelector((state) => state.user.profile);
    const remainingReview = userData?.value?.profileData?.membershipProfile?.remainingReviews || 0;
    const [animatedFavs, setAnimatedFavs] = useState({});
    const [deletedPostId, setDeletedPostId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [videoThumbnails, setVideoThumbnails] = useState({});
    const [limit, setLimit] = useState(100);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);

    const COUNT = Math.ceil(postCountData?.data?.count / limit)
    const [sortOption, setSortOption] = useState('reviewed');
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [avoidUpdateTrigger, setAvoidUpdateTrigger] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");


    useEffect(() => {
        switch (sortOption) {
            case 'oldToNew':
                setSkip(0)
                setPage(1)
                break;
            case 'newToOld':
                setSkip(0)
                setPage(1)
                break;
            case 'created':
                setSkip(0)
                break;
            case 'reviewRequested':
                setSkip(0)
                break;
            case 'reviewed':
                setSkip(0)
                break;
            case 'favourites':
                setSkip(0)
                break;
            default:
            // setSortOrder(1);
        }
    }, [sortOption]);




    //to get remainingReviews
    // useEffect(() => {
    //     if (jwtToken) {
    //         dispatch(getUserProfile(jwtToken))
    //     }
    // }, [jwtToken, dispatch])

    const handleChange = (event, value) => {
        setPage(value);
        setSkip((value - 1) * limit);
        window.scrollTo(0, 0);
    };



    // Function to construct the conditions
    const constructConditions = () => {
        const { start, end } = getTimeRange(sortOption);
        let conditions = { "poster": userId };

        // Add time range condition if applicable
        if (start && end) {
            conditions.createdAt = { start, end };
        }


        if (sortOption === "created") {
            conditions.state = "created"
        } else if (sortOption === "reviewRequested") {
            conditions.state = "review_requested"
        } else if (sortOption === "reviewed") {
            conditions.state = "reviewed"
        } else if (sortOption === "favourites") {
            conditions.state = "reviewed"
            conditions.fav = true;
        } else {
            conditions.state = ""
        }

        return conditions;
    }

    //All Posts Count
    useEffect(() => {
        if (jwtToken && userId && !avoidUpdateTrigger && env !== "standalone") {
            const conditions = constructConditions();
            dispatch(getAllPostCounts(jwtToken, {
                "condition": conditions,
            }));
        }
    }, [jwtToken, userId, page, sortOption, updateTrigger]);



    //All Posts Details
    useEffect(() => {

        if (jwtToken && userId && !avoidUpdateTrigger && env !== "standalone") {
            const conditions = constructConditions();
            dispatch(getVideos(jwtToken, {
                "condition": conditions,
                "sort": {
                    "updatedAt": -1
                },
                "skip": skip,
                 "limit": limit,
            }));
        }
    }, [jwtToken, userId, page, sortOption, updateTrigger, skip, limit]);


    useEffect(() => {
        if (data?.length > 0 && jwtToken && env !== "standalone") {
            const fetchThumbnail = async (reviewPostId, tnPath) => {
                try {
                    const thumbnailUrl = await getFetchGamePostFileThumbnail(jwtToken, reviewPostId, tnPath);
                    setVideoThumbnails(prev => ({ ...prev, [reviewPostId]: thumbnailUrl }));
                } catch (error) {
                    console.error('Error fetching thumbnail:', error);
                }
            };
            data?.forEach((item) => {
                fetchThumbnail(item._id, item.game[0]?.tnPath);
            });
        }
    }, [data, jwtToken, userId]);



    function RenderNoVideosView() {
        return (
            <div className={styles.no_video_view_cont}>
                <div>
                    <h6>Once report generate your gameplay report will appear here</h6>
                </div>
            </div>
        );
    }

    function RenderNoVideosWithSearchCondition({ sortOption }) {
        let searchKey

        if (sortOption === "created") {

            searchKey = "Uploaded"
        } else if (sortOption === "reviewRequested") {
            searchKey = "Requested"
        } else if (sortOption === "reviewed") {
            searchKey = "Completed"
        } else if (sortOption === "favourites") {
            searchKey = "Favourites"
        }
        else {
            searchKey = ""
        }

        return (
            <div className={styles.no_video_view_cont}>
                <div>
                    <h5>No video found </h5>
                </div>
            </div>
        );
    }

    function handleClickViewReport(postData) {
        localStorage.setItem("reviewPostData", JSON.stringify(postData));
        navigate("/game-feedback/coach-feedback")
    }


    async function handleClickFav(postData) {
        const postId = postData._id;
        const favToggle = !postData.fav;
        const res = await markGamePostFav(jwtToken, postId, favToggle);

        if (res && res.code === 200) {
            setAnimatedFavs(prev => ({ ...prev, [postId]: true }));
            dispatch(toggleFavVideoPost(postId));

            setTimeout(() => {
                setAnimatedFavs(prev => ({ ...prev, [postId]: false }));
            }, 300);
        } else {
            dispatch(setAlertMessage(res.message || "Something went wrong"));
            dispatch(setAlertType("error"));
        }
    }

     const handleClickDel = (postData) => {
            setSelectedPost(postData);
            setOpenDeleteDialog(true);
        };
    
        const confirmDelete = async () => {
            if (!selectedPost) return;
    
            setOpenDeleteDialog(false);
    
            if (env === "standalone") {
                return null;
            }
    
            const res = await deleteGamePost(jwtToken, selectedPost._id);
            setAvoidUpdateTrigger(true);
    
            if (res && res.code === 200) {
                setTimeout(() => {
                    setAvoidUpdateTrigger(false);
                }, 1000);
    
                setDeletedPostId(selectedPost._id);
                setTimeout(() => {
                    dispatch(deleteVideoPost(selectedPost._id));
                    setDeletedPostId(null);
    
                    if (data.length === 1 && page > 1) {
                        setAvoidUpdateTrigger(false);
                        setPage(prevPage => prevPage - 1);
                        setSkip(prevSkip => prevSkip - limit);
                    }
    
                    setUpdateTrigger(prev => !prev);
                }, 800);
            } else {
                dispatch(setAlertMessage(res.message || "Something went wrong"));
                dispatch(setAlertType("error"));
            }
        };


    async function handlePlayClick(video, isOpen = true) {
        setSelectedVideo(video)
        const postId = video._id
        const videoLinkInObjectStore = video.game[0].path;
        localStorage.setItem("videoLinkInObjectStore" , videoLinkInObjectStore);
        localStorage.setItem("reviewPostId", postId);
        localStorage.setItem("reviewPost", JSON.stringify(video));
        localStorage.setItem(`${postId}_reviewPost`, JSON.stringify(video));
        localStorage.setItem("prevPath", JSON.stringify(pathname));
        localStorage.setItem("prevPath", JSON.stringify(pathname));
        console.log("My reports", )
        navigate(`feedback/${postId}`, { state: { videoTags: video.tags } })


        if (env === "standalone") {
            if (video.state === "reviewed") {
                // setIsModalOpen(true)
                navigate(`feedback/${postId}`, { state: { videoTags: video.tags} })
            } else {
                setOpen(isOpen);
            }

            return null;
        }
    }

    const handleSortChange = (newSortOption) => {
        setSortOption(newSortOption);
        setPage(1)
        setSkip(0)
        setUpdateTrigger(prev => !prev); // Trigger update to refetch data
    };


    const openModal = (video) => {
        setSelectedVideo(video);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedVideo(null);
        setIsModalOpen(false);
    };

    const setSearchText = (searchText) => {
        console.log("setting searchterm" ,searchText);
        setSearchTerm(searchText);
        console.log("setting searchterm 1" , searchTerm);
    };


    const videocoutn = postCountData?.data?.count

    return (
        <>
            <FullScreenDialog open={open} setOpen={setOpen} selectedVideo={selectedVideo} />
                <div className={styles.filters}>
                    <h1>My Reports</h1>

                    {/* <div className={styles.counts}>
                        Remaining video count <span>{data?.length || 0}/{videocoutn || 0}</span>
                    </div> */}
                </div>

            <SearchVideoWithTagName setSearchText={setSearchText}/>
            <div className={styles.videos_section}>
                {jwtToken && (
                    <>
                        <div className={styles.header_section}>
                            {/* <CustomDropdown
                                options={dropdownOptions}
                                value={sortOption}
                                onChange={handleSortChange}
                            /> */}
                            {/* <div className={styles.cartCont}>
                                        <div className={styles.cartContent}>
                                            <div className={styles.spanOne}>Reports remaining</div>
                                            <div className={styles.spanTwo}>{remainingReview}/12</div>
                                        </div>

                                        <div className={styles.cartIcon} onClick={() => handleClickBuyCredit()}>
                                            <i className="fa-solid fa-cart-plus"></i>
                                        </div>

                                    </div> */}
                        </div>
                        {isStarted ? (
                            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "25vh" }}>
                                <CircularProgress /> <p>Loading....</p>
                            </Box>
                        ) : isError ? (
                            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "25vh" }}>
                                <p className={styles.error}>{error}</p>
                            </Box>
                        ) : (env === "standalone" ? filteredVideos.length > 0 : data.length > 0) ? (
                            <div >
                                <div className={styles.wrp}>
                                    {

                                        (env === "standalone" ? filteredVideos : data)?.filter(video => 
                                            ((searchTerm.length === 0) || video?.tags.some(tag => tag.tagName.toLowerCase().includes(searchTerm.toLowerCase())))
                                        ).map((ele, index) => {
                                            console.log(`Video ${index}`, ele);
                                            const thumbnailUrl = videoThumbnails[ele._id] || thumbnail;
                                            const isFavAnimated = animatedFavs[ele._id];

                                            return (
                                                <div className={`${styles.video_wrapper} ${deletedPostId === ele._id ? styles.deleting : ''}`}
                                                    key={ele._id}>
                                                    <div className={styles.data_icons_container}>
                                                        <div className={styles.date_cont}>
                                                            <p>{dataFormatter(ele.updatedAt)}</p>
                                                            <p className={styles.videoTitle}>
                                                            {truncateTitle(extractTitle(ele.game[0]?.path))}
                                                        </p>
                                                        </div>
                                                        

                                                        <div className={styles.icons_cont}>

                                                            <i
                                                                className={`fa-${ele.fav ? 'solid' : 'regular'} fa-bookmark ${styles.favIcon} ${isFavAnimated ? styles.animate : ''}`}
                                                                onClick={() => handleClickFav(ele)}
                                                            ></i>



                                                            <i className="fa-solid fa-delete"></i>
                                                            <DeleteForeverIcon
                                                            sx={{ color: "#247E45", cursor: "pointer" }}
                                                            onClick={() => handleClickDel(ele)}
                                                        />

                                                        {/* Delete Confirmation Dialog */}
                                                        <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                                                            <DialogTitle>Confirm Deletion</DialogTitle>
                                                            <DialogContent>Are you sure you want to delete this video?</DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">Cancel</Button>
                                                                <Button onClick={confirmDelete} color="error">Delete</Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                        </div>

                                                    </div>

                                                    <div className={styles.video_box}   onClick={() => handlePlayClick(ele)}>
                                                        <div className={styles.video_cont} style={{ backgroundImage: `url(${thumbnailUrl})` }}>
                                                            {
                                                                ele.state === "reviewed" ? <Button onClick={() => handlePlayClick(ele)}>View report</Button> : <Button style={{ backgroundColor: "#E0E1E6", color: "#000000", }} disabled={true}>Waiting on Feedback</Button>
                                                            }
                                                        </div>
                                                        <div className={styles.icons}>

                                                            {
                                                                ele.state === "reviewed" ? <div className={styles.play_icon} onClick={() => handlePlayClick(ele)}>
                                                                    <FontAwesomeIcon icon={faCirclePlay} />
                                                                    {/* <span>View</span> */}
                                                                </div> : <div className={styles.play_icon} onClick={() => handlePlayClick(ele)}>
                                                                    <FontAwesomeIcon icon={faCirclePlay} />
                                                                    {/* <span>View</span> */}
                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>

                                <div>{data?.filter(video => 
                                        ((searchTerm.length === 0) || video?.tags.some(tag => tag.tagName.toLowerCase().includes(searchTerm.toLowerCase())))
                                    ).length === 0 &&  <p className={styles.noVideosFoundForTag}> No matching videos found for this tag name</p>}
                                </div>

                                {/* <Stack spacing={2} sx={{ marginBlock: "2rem", alignItems: "end" }}>
                                    <Pagination count={COUNT} page={page} onChange={handleChange} size='large' />
                                </Stack> */}

                            </div>

                        ) : (
                            sortOption == "favourites" || sortOption == "created" || sortOption == "reviewRequested" || sortOption == "reviewed" ? <RenderNoVideosWithSearchCondition sortOption={sortOption} /> : <RenderNoVideosView />
                        )}
                    </>
                )}
                {!jwtToken && <RenderNoVideosView />}


                {selectedVideo && (
                    <Dialog
                        open={isModalOpen}
                        onClose={closeModal}
                        maxWidth={false} // Disable default maxWidth
                        fullScreen
                        sx={{
                            '& .MuiDialog-paper': {
                                margin: 0, // Remove default margin
                                width: '100%', // Full width
                                height: '100%', // Full height
                                maxWidth: '100%', // Prevent max-width limitation
                            },
                        }}
                    >
                        <DialogTitle style={{ display: "flex", justifyContent: "flex-end" }}>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={closeModal}
                                aria-label="close"
                                style={{
                                    position: 'relative',
                                    right: 0,
                                    top: 8,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            {/* Video feedback or content component */}
                            <CoachFeedback
                                videoId={selectedVideo._id}
                            />
                        </DialogContent>

                    </Dialog>
                )}
            </div>


        </>
    );
}

export default GameReports