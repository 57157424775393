import React from "react";

import './VideoTagsContainer.scss'
import VideoTag from '../VideoTag/VideoTag'

const VideoTagsContainer = ({ videoTagList }) => {

  return (
    <div className = "video-tags-container">
    {videoTagList.map((tagDetails, index) => 

      (<VideoTag tagDetails={tagDetails} index={index}/>
      ))
    }
    </div>
   
  );
};

export default VideoTagsContainer;
