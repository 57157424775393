import React, { useEffect, useRef, useState } from "react";
import { Dialog, LinearProgress, useMediaQuery } from "@mui/material";
import { PaperProps } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsLoginDialog,
  setUploadGuidelineVideoDialogOpen,
  setVideoUploadDialogOpen,
} from "../../redux/reducers/loginSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { PUT_CREATE_GAME, GET_ALL_TAGS } from "../../config/endpoints";
import styles from "./UploadVideoModel.module.scss";
import { setAlertMessage, setAlertType } from "../../redux/reducers/alertSlice";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getAllPostCounts, getVideos } from "../../redux/network/videoApi";
import { toggleVideoSortDropdown } from "../../redux/reducers/videoSlice";
import VideoTagsSelectContainer from "../Tags/VideoTagsSelectContainer/VideoTagsSelectContainer";
import RecordRTC from "recordrtc";

const UploadVideoModel = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.login.isVideoUploadDialogOpen);

  const navigate = useNavigate();
  const jwtToken = localStorage.getItem("BQS_TOKEN");
  const userId = localStorage.getItem("BQS__ID");
  const userName = localStorage.getItem("BQS_USER_NAME");
  const env = process.env.REACT_APP_ENV;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (env !== "standalone") {
      if (!jwtToken) dispatch(setIsLoginDialog(true));
    }
  }, [jwtToken]);

  const [uploading, setUploading] = useState(false); // State to track uploading status
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress
  const [paused, setPaused] = useState(false); // State to track upload pause status
  const [uploadSuccess, setUploadSuccess] = useState(false); // State to track upload success
  const [uploadResponse, setUploadResponse] = useState("");

  const [selectedFile, setSelectedFile] = useState(null); // State to track selected file
  const [timeRemaining, settimeRemaining] = useState("");

  const intervalRef = useRef(); // Ref to hold interval reference
  const startTimeRef = useRef(); // Ref to hold start time
  const elapsedTimeRef = useRef(0); // Ref to hold elapsed time
  const remainingTimeRef = useRef(10); // Ref to hold remaining time (in seconds)

  const cancelTokenSource = useRef(null); // Ref to hold cancel token
  const [selectedTagListInParent, setSelectedTagListInParent] = useState([]);
  const TAGS_QUERY_KEY = "&tags=";

  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const videoRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:1024px)");

  const [tempVideoBlob, setTempVideoBlob] = useState(null);

  const [recordingTime, setRecordingTime] = useState(0);
  const [recordingInterval, setRecordingInterval] = useState(null);
  const RECORDING_LIMIT = 2 * 60; // 5 minutes in seconds

  const generateFileName = () => {
    const date = new Date();
    const timestamp = date
      .toISOString()
      .replace(/[:.]/g, "-")
      .replace("T", "_")
      .slice(0, -5);
    return `${userName}_${timestamp}.mp4`;
  };

  const isClosingRef = useRef(false);

  const startRecording = async () => {
    try {
      // 1080 configuration
      const constraints = {
        video: {
          facingMode: { exact: "environment" }, // This forces the back camera
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
        audio: true,
      };

      // Fallback to any available camera if back camera fails
      const fallbackConstraints = {
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
        audio: true,
      };

      let mediaStream;
      try {
        // First try with back camera
        mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      } catch (err) {
        // If back camera fails, try with default camera
        console.log("Back camera not available, trying fallback...");
        mediaStream = await navigator.mediaDevices.getUserMedia(
          fallbackConstraints
        );
      }

      // Set stream in state and video element
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }

      // Determine optimal recording settings for current browser
      let recorderConfig = {
        type: "video",
        mimeType: "video/mp4",
        videoBitsPerSecond: 2000000, // 1.5 Mbps (Balanced quality & size)
        audioBitsPerSecond: 64 * 1024, // 64 Kbps audio bitrate
        frameInterval: 1, // Lower frame interval for smoother recording
        frameRate: 30, // Smooth 30 FPS recording
        keyFrameInterval: 30, // One keyframe every second
        disableLogs: false,
      };

      // Check browser compatibility for MP4 recording
      if (!MediaRecorder.isTypeSupported("video/mp4")) {
        console.log("MP4 recording not supported directly, using fallback...");
        // Use WebM for recording (most universally supported) and convert later
        recorderConfig.mimeType = "video/webm;codecs=vp8,opus";
      }

      const recorder = new RecordRTC(mediaStream, recorderConfig);

      console.log("Recording format:", recorderConfig.mimeType);
      recorder.startRecording();

      setMediaRecorder(recorder);
      setIsRecording(true);

      // Start the timer
      const interval = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime + 1;
          if (newTime >= RECORDING_LIMIT) {
            document.getElementById("stopRecordingButton").click();
            clearInterval(interval);
            return RECORDING_LIMIT;
          }
          return newTime;
        });
      }, 1000);

      setRecordingInterval(interval);

      // Auto stop at time limit
      setTimeout(() => {
        if (isRecording) {
          document.getElementById("stopRecordingButton").click();
        }
      }, RECORDING_LIMIT * 1000);
    } catch (error) {
      console.error("Error accessing camera:", error);
      setUploadResponse("Error accessing camera. Please check permissions.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && isRecording) {
      mediaRecorder.stopRecording(async () => {
        const blob = mediaRecorder.getBlob();
        console.log("Recorded video format:", blob.type);
        console.log("Recorded video size:", blob.size);

        if (!isClosingRef.current) {
          const maxSize = 200 * 1024 * 1024; // 200MB
          if (blob.size > maxSize) {
            setUploadResponse(
              "Recorded video is too large. Please record a shorter video (less than 200MB)."
            );
            setSelectedFile(null);
          } else {
            const autoFileName = generateFileName();

            // Handle format conversion if needed
            let finalBlob = blob;
            if (blob.type !== "video/mp4") {
              try {
                // Create an mp4 file even if recorded in another format
                const file = new File([blob], autoFileName, {
                  type: "video/mp4",
                });
                setSelectedFile(file);
              } catch (err) {
                console.error("Error converting video format:", err);
                // Fallback to original format with MP4 extension
                const file = new File([blob], autoFileName, {
                  type: "video/mp4",
                });
                setSelectedFile(file);
              }
            } else {
              const file = new File([blob], autoFileName, {
                type: "video/mp4",
              });
              setSelectedFile(file);
            }
          }
        }
        stream.getTracks().forEach((track) => track.stop());
        setIsRecording(false);
        setTempVideoBlob(null);
      });
      clearInterval(recordingInterval);
      setRecordingTime(0);
    }
  };

  const formatRemainingTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleRecordingComplete = () => {
    stopRecording();
  };

  const handleOnClickbrowseFile = async () => {
    // await clearDatabase()
    setUploadResponse("");
    // setUploadSuccess(false)
    // setSelectedFile(null)        setUploadProgress(0)
  };

  const handleFileInput = async (event) => {
    setUploadResponse("");
    setUploadSuccess(false);
    setUploadProgress(0);

    const file = event?.target?.files[0];

    const maxSize = 200 * 1024 * 1024;
    if (file) {
      if (file.size > maxSize) {
        setUploadResponse(
          "Selected file is too large. Please choose a file less than 200MB."
        );
        return;
      } else {
        const newFile = new File([file], file.name, { type: file.type });
        setSelectedFile(newFile);
      }
    }
  };

  const handleUpload = async () => {
    if (paused) {
      startTimeRef.current = Date.now() - elapsedTimeRef.current;
    } else {
      startTimeRef.current = Date.now(); // Record start time
      setUploadProgress(0); // Reset progress to 0%
      setUploadSuccess(false); // Remove success message
    }

    if (!jwtToken) {
      dispatch(setIsLoginDialog(true));
      dispatch(setAlertType("error"));
      dispatch(setAlertMessage("Please Login to upload video"));
      return;
    }

    if (!selectedFile) {
      setUploadResponse("Please select video to upload");
      return;
    }

    // Allowed file types
    const allowedExtensions = [".mov", ".mkv", ".mp4"];
    const allowedMimeTypes = [
      "video/mp4",
      "video/quicktime",
      "video/x-matroska",
    ];

    // Extract the file extension and MIME type
    const fileName = selectedFile.name.toLowerCase();
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));
    const mimeType = selectedFile.type;

    // Validate the file type
    if (
      !allowedExtensions.includes(fileExtension) &&
      !allowedMimeTypes.some((type) => mimeType.startsWith(type.split("/")[0]))
    ) {
      setUploadResponse(
        "Invalid file type. Please upload a video in .mov, .mkv, .mp4 formats only"
      );
      return;
    }

    console.log("selectedFile", selectedFile);

    setUploading(true);
    setUploadResponse(null);

    // Create a new cancel token source
    cancelTokenSource.current = axios.CancelToken.source();
    const startTime = Date.now(); // Define and initialize startTime

    const url = `${PUT_CREATE_GAME}${createQueryParamsForTags()}`;

    try {
      const response = await axios.put(url, selectedFile, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          filename: selectedFile.name,
          streamsize: selectedFile.size,
        },
        cancelToken: cancelTokenSource.current.token,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          console.log(progress);
          setUploadProgress(progress);

          // Calculate remaining time
          const elapsedTime = Date.now() - startTime;
          const remainingBytes = progressEvent.total - progressEvent.loaded;
          const uploadSpeed = progressEvent.loaded / elapsedTime;
          const remainingTimeInSeconds = remainingBytes / uploadSpeed;
          console.log("Remaining time:", remainingTimeInSeconds);
          // settimeRemaining(Math.round(remainingTimeInSeconds));
          settimeRemaining(
            Math.round(Math.round(remainingTimeInSeconds / 1000))
          );
        },
      });

      // Upload successful
      // console.log("Upload successful:", response.data);
      console.log("Upload response:", response);

      if (response.data.code === 200) {
        setUploadResponse("Video uploaded successfully");
        setUploadSuccess(true);
        dispatch(setVideoUploadDialogOpen(false));
        setSelectedFile(null);
        setUploadResponse("");

        dispatch(toggleVideoSortDropdown());

        // dispatch(getVideos(jwtToken, {
        //     "condition": {
        //         "poster": userId,
        //         "state": "created"
        //     },
        //     "sort": {
        //         "createdAt": -1
        //     },
        //     "skip": 0,
        //     "limit": 3,
        // }));

        // dispatch(getAllPostCounts(jwtToken, {
        //     "condition": {
        //         "poster": userId,
        //         "state": "created"
        //     },
        // }));
      } else {
        if (response.status !== 200) {
          throw new Error(
            response?.data?.message ||
              "Something went wrong while uploading video "
          );
        }
      }
    } catch (error) {
      // Upload failed or cancelled
      if (axios.isCancel(error)) {
        console.log("Upload cancelled:", error?.message);
      } else {
        console.error("Upload failed:", error);
        if (error?.response?.data?.code === 400) {
          setUploadResponse(
            "Video already exists. Please upload a different video"
          );
        } else if (error?.response?.data?.code === 401) {
          //Anauthorized user
          dispatch(setIsLoginDialog(true));
          localStorage.clear();
        } else {
          setUploadResponse(
            error?.response?.data?.message || "Upload failed. Please try again."
          );
        }
      }
    } finally {
      // Reset uploading state and cancel token
      setUploading(false);
      cancelTokenSource.current = null;
    }
  };

  const createQueryParamsForTags = () => {
    return selectedTagListInParent.length > 0
      ? TAGS_QUERY_KEY + selectedTagListInParent.map((tag) => tag._id).join(",")
      : "";
  };

  const handlePause = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Upload paused by user");
      cancelTokenSource.current = null;
    }
  };

  const handleResume = () => {
    handleUpload();
  };

  const handleCancel = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Upload cancelled by user");
      cancelTokenSource.current = null;
    }
    if (isRecording) {
      // Stop the recording
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
      }
      // Stop all tracks in the stream
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      clearInterval(recordingInterval);
      setRecordingTime(0);
      // Reset recording states
      setIsRecording(false);
      setStream(null);
      setMediaRecorder(null);
      setTempVideoBlob(null);
    }
    setUploading(false);
    setSelectedFile(null);
    setUploadProgress(0);
    setUploadResponse("");
    setTempVideoBlob(null);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const maxSize = 200 * 1024 * 1024;
    if (file) {
      if (file.size > maxSize) {
        setUploadResponse(
          "Selected file is too large. Please choose a file less than 200MB."
        );
        return;
      } else {
        const newFile = new File([file], file.name, { type: file.type });
        setSelectedFile(newFile);
      }
    }
    event.target.classList.remove(styles.drag_over);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    // Add visual indication for drag and drop area
    event.target.classList.add(styles.drag_over);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.target.classList.remove(styles.drag_over);
  };

  const handleNext = () => {
    navigate("/game-posts/game-videos");
  };

  const handleClose = () => {
    isClosingRef.current = true; // Set closing flag

    // Stop recording if active
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    // Reset all states
    setIsRecording(false);
    setStream(null);
    setMediaRecorder(null);
    setSelectedFile(null);
    setUploadResponse("");
    clearInterval(recordingInterval);
    setRecordingTime(0);

    // Close dialog
    dispatch(setVideoUploadDialogOpen(false));
    document.body.classList.remove("modal-open");

    // Reset closing flag after a short delay
    setTimeout(() => {
      isClosingRef.current = false;
    }, 100);
  };

  const handleOpenUploadVideoGuidelineDialog = () => {
    dispatch(setUploadGuidelineVideoDialogOpen(true));
    dispatch(setVideoUploadDialogOpen(false));
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <div className={styles.container_upload_section}>
        <div className={styles.header}>
          <h2>Upload your video</h2>
          <button onClick={handleClose}>X</button>
        </div>
        <div
          className={styles.left_container}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          {isMobile ? (
            <div className={styles.record_container}>
              <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                style={{
                  display: isRecording ? "block" : "none",
                  width: "100%",
                  height: "auto",
                  maxHeight: "50vh",
                  objectFit: "cover",
                  borderRadius: "8px",
                  WebkitAppearance: "none",
                  background: "#000",
                }}
              />
              {!isRecording && !selectedFile ? (
                <button
                  className={styles.record_button}
                  onClick={startRecording}
                >
                  Start Recording 2 mins
                </button>
              ) : isRecording ? (
                <div className={styles.recording_container}>
                  <button
                    id="stopRecordingButton"
                    className={`${styles.record_button} ${styles.recording}`}
                    onClick={handleRecordingComplete}
                  >
                    Stop Recording (
                    {formatRemainingTime(RECORDING_LIMIT - recordingTime)})
                  </button>
                  <div className={styles.recording_progress}>
                    <div
                      className={styles.progress_bar}
                      style={{
                        width: `${(recordingTime / RECORDING_LIMIT) * 100}%`,
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {!tempVideoBlob && (
                <div className={styles.browse_container}>
                  <p className={styles.fileSupportedType}>
                    Files supported: MP4, MOV, MKV
                  </p>
                  <p className={styles.fileSupportedType}>Size Limit:200MB</p>
                  {!isRecording && (
                    <p
                      className={styles.browseFile}
                      style={{ marginTop: "0rem", marginBottom: "0rem" }}
                    >
                      or{" "}
                      <label
                        onClick={(e) => {
                          if (uploading) e.preventDefault();
                          else handleOnClickbrowseFile();
                        }}
                        style={{
                          cursor: uploading ? "not-allowed" : "pointer",
                        }}
                      >
                        browse files
                        <input
                          type="file"
                          onChange={handleFileInput}
                          accept=".mov,.mkv,.mp4,video/*"
                          style={{ width: "0px", opacity: 0 }}
                          disabled={uploading}
                        />
                      </label>
                    </p>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className={styles.browse_container}>
              <svg
                viewBox="0 0 69 86"
                fill="#247E45"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.04158 0.5C3.92547 0.5 2.85506 0.947766 2.06584 1.7448C1.27663 2.54183 0.833252 3.62283 0.833252 4.75V72.75C0.833252 76.1315 2.16338 79.3745 4.53103 81.7656C6.89868 84.1567 10.1099 85.5 13.4583 85.5H55.5416C58.8899 85.5 62.1012 84.1567 64.4688 81.7656C66.8364 79.3745 68.1666 76.1315 68.1666 72.75V26C68.1663 24.8729 67.7228 23.7921 66.9335 22.9953L45.8919 1.74525C45.1028 0.948165 44.0326 0.500241 42.9166 0.5H5.04158ZM42.9166 10.7595L58.0077 26H42.9166V10.7595ZM45.0207 57.3055C45.6604 56.9325 46.1916 56.396 46.561 55.7499C46.9303 55.1038 47.1247 54.371 47.1247 53.625C47.1247 52.879 46.9303 52.1462 46.561 51.5001C46.1916 50.854 45.6604 50.3175 45.0207 49.9445L32.3957 42.5835C31.756 42.2105 31.0303 42.0141 30.2916 42.0141C29.5529 42.0141 28.8272 42.2105 28.1875 42.5835C27.5477 42.9565 27.0165 43.493 26.6471 44.139C26.2777 44.7851 26.0833 45.518 26.0832 46.264V60.986C26.0833 61.732 26.2777 62.4649 26.6471 63.111C27.0165 63.757 27.5477 64.2935 28.1875 64.6665C28.8272 65.0395 29.5529 65.2359 30.2916 65.2359C31.0303 65.2359 31.756 65.0395 32.3957 64.6665L45.0207 57.3055Z"
                  fill="#247E45"
                />
              </svg>
              <h2>Drag & Drop</h2>
              <p className={styles.fileSupportedType}>
                Files supported: MP4, MOV, MKV
              </p>
              <p className={styles.fileSupportedType}>Size Limit:200MB</p>
              <p className={styles.browseFile}>
                or{" "}
                <label
                  onClick={(e) => {
                    if (uploading) e.preventDefault();
                    else handleOnClickbrowseFile();
                  }}
                  style={{ cursor: uploading ? "not-allowed" : "pointer" }}
                >
                  browse files
                  <input
                    type="file"
                    onChange={handleFileInput}
                    accept=".mov,.mkv,.mp4,video/*"
                    style={{ width: "0px", opacity: 0 }}
                    disabled={uploading}
                  />
                </label>{" "}
                on your computer
              </p>
            </div>
          )}

          <p className={styles.filename}>{selectedFile?.name}</p>
          <p
            className={`${
              uploadSuccess ? styles.success_message : styles.error_message
            }`}
          >
            {uploadResponse ? uploadResponse : ""}
          </p>

          {uploading && (
            <>
              <div className={styles.progress_container}>
                <div className={styles.button_group}>
                  {selectedFile?.name && (
                    <p className={styles.filename}>{selectedFile?.name}</p>
                  )}
                  <div>
                    {/* {paused ? (
                    <button onClick={handleResume} disabled={uploadProgress === 100}>
                      <i className="fa-solid fa-play"></i>
                    </button>
                  ) : (
                    <button onClick={handlePause}>
                      <i className="fa-solid fa-pause"></i>
                    </button>
                  )} */}
                    <button
                      onClick={handleCancel}
                      disabled={uploadProgress === 100}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  className={styles.progress_bar}
                />
                <div className={styles.progress_info}>
                  <p>{uploadProgress}%</p>
                  {/* <p>{remainingTimeRef.current}s remaining</p> */}
                  <p>{timeRemaining}s remaining</p>
                </div>
              </div>
            </>
          )}

          <VideoTagsSelectContainer
            setSelectedTagListInParent={setSelectedTagListInParent}
          />

          <div className={styles.button_container}>
            <button
              onClick={handleUpload}
              disabled={uploadProgress > 0 || isRecording}
            >
              UPLOAD
            </button>
          </div>
        </div>
        {/* <LinearProgress
          variant="determinate"
          value={uploadProgress}
          className={styles.progress_bar}
        />
        <div className={styles.progress_info}>
          <p>{uploadProgress}%</p>
          <p>{timeRemaining}s remaining</p>
        </div> */}
      </div>
    </Dialog>
  );
};

{
  /* <div className={styles.bottom_wrap}>
                <p onClick={handleOpenUploadVideoGuidelineDialog}>Watch the guideline video <i className="fa-solid fa-arrow-up-right-from-square"></i></p>
            </div> */
}

export default UploadVideoModel;
