import React, { useEffect, useRef, useState } from "react";
import "./Home.scss";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NorthIcon from '@mui/icons-material/North';
import { faL } from "@fortawesome/free-solid-svg-icons";
import { getAllTestimonials } from "../../redux/network/genericApi";
import { setIsLoginDialog } from "../../redux/reducers/loginSlice";
import { useDispatch } from "react-redux";
import multisports from "../BlogDetails/multisports.jpg";
export default function Home() {
  const [testimonials, setTestimonials] = useState([]);
  const testimonialsRef = useRef(null);
  const userRole = localStorage.getItem("BQS_ROLE")
  const dispatch = useDispatch();
  // console.log("testimonials===>", testimonials)

  const fetchTestimonials = async () => {
    try {
      const formData = {
        "condition": {
          "_id": ""
        }
      };

      const response = await getAllTestimonials(formData);

      // console.log("testimonials response", response);


      if (response.code === 200 && Array.isArray(response.data)) {
        setTestimonials(response.data);
      }

    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const [expanded, setExpanded] = useState({});

  const toggleReadMore = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  // Close expanded testimonial when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (testimonialsRef.current && !testimonialsRef.current.contains(event.target)) {
        setExpanded({}); // Reset expanded state to collapse all testimonials
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isArrowHover, setisArrowHover] = useState(false)

  const handleArrowButtonMouseEnter = () => {
    setisArrowHover(true);
  };

  const handleArrowButtonMouseLeave = () => {
    setisArrowHover(false);

  };

  const report = [
    {
      head: "Record & Upload ",
      sanp: "Your Videos",
      pera: "Record your training drills, practice matches, or competitive games using your smartphone. Upload the video to your Journal and follow the guidelines for accurate feedback and insights!",
      btn: "UPLOAD NOW",
      img: "assets/1st.jpeg.jpg",
      redirectionPath: userRole === "player"
        ? "/game-posts/game-videos"
        : userRole === "coach"
          ? "/coach_flow/pending-video"
          : dispatch(setIsLoginDialog(true))
    },
    {
      head: "Request Your ",
      sanp: "Personalized Feedback",
      pera: "Upload your video and choose the area you want to improve. Our experts and Visual Intelligence Platform will create a custom feedback report just for you!",
      btn: "UPLOAD NOW",
      img: "assets/2nd.jpeg",
      redirectionPath: userRole === "player"
        ? "/game-posts/game-videos"
        : userRole === "coach"
          ? "/coach_flow/pending-video"
          : dispatch(setIsLoginDialog(true))
    },
    {
      head: "Access All Your Videos and Reports ",
      sanp: "My Journal",
      scnSnap: "in ",
      pera: "Easily find all your uploaded videos and personalized AI feedback reports in your 'My Journal'—your one-stop space to track and improve your performance!",
      btn: "MY JOURNAL",
      img: "assets/3rd.jpeg",
      redirectionPath: userRole === "player"
        ? "/game-posts/game-videos"
        : userRole === "coach"
          ? "/coach_flow/pending-video"
          : dispatch(setIsLoginDialog(true))
    },
  ];
  const blog = [
          {
      head: "From Dirt Paths to Global Stages",
      pera: "In the heart of India's villages, a revolution is brewing. It's not just about talent anymore—it's about how technology, passion, and relentless.....",
      ownerName: "Visist Team",
      date: "5 March 2025",
      type: [
        { typeName: "DreamBig" },
        { typeName: "IndianAthletes" },

      ],
      img: multisports,
    },
    {
      head: "Perfect Smash in Badminton!",
      pera: "The badminton smash is a powerful downward attacking shot designed to end a rally or elicit a weak response from opponents. The world record ...",
      ownerName: "Visist Team",
      date: "30 May 2024",
      type: [
        { typeName: "Grip Technique" },
        { typeName: "Positioning" },

      ],
      img: "assets/perfectSmash.jpg",
    },
    {
      head: "The Power of Video Analysis in Sports",
      pera: "Video analysis is a transformative tool for athletes and coaches aiming to enhance performance and strategy. By reviewing game footage, you can spot..",
      ownerName: "Visist Team",
      date: "2 JUNE 2024",
      type: [
        { typeName: "Video Analysis" },
        { typeName: "Benefits" },

      ],
      img: "assets/powerOfVideoAna.png",
    },
    {
      head: "VIDEO ANALYSIS IN PERFORMANCE SPORTS",
      pera: "Video analysis has become an integral tool for sports coaches and athletes to enhance performance. Here are four....",
      ownerName: "Visist Team",
      date: "28 June 2024",
      type: [
        { typeName: "Video analysis" },
        { typeName: "Benefits" },

      ],
      img: "assets/blog3Img.png",
    },
    // {
    //   head: "Learn more",
    //   pera: "Pellentesque sit scsw sfcs consetur odio id blandit pharetra sed non. Dignissim at non hendrerit feeum est feugiat. Eu phasellus agfd pellentesque iaculis faucibqet id wfwf eget. Voxcan hendrerit fermentum est us a.......",
    //   ownerName: "Santosh G",
    //   date: "FEBRUARY 23, 2024",
    //   type: [
    //     { typeName: "TRAVEL GUIDE" },
    //     { typeName: "VEVE WQW" },

    //   ],
    //   img: "assets/blog1Img.png",
    // },
    // {
    //   head: "Learn more",
    //   pera: "Pellentesque sit scsw sfcs consetur odio id blandit pharetra sed non. Dignissim at non hendrerit feeum est feugiat. Eu phasellus agfd pellentesque iaculis faucibqet id wfwf eget. Voxcan hendrerit fermentum est us a.......",
    //   ownerName: "Santosh G",
    //   date: "FEBRUARY 23, 2024",
    //   type: [
    //     { typeName: "TRAVEL GUIDE" },
    //     { typeName: "VEVE WQW" },

    //   ],
    //   img: "assets/blog2Img.png",
    // },
    // {
    //   head: "Learn more",
    //   pera: "Pellentesque sit scsw sfcs consetur odio id blandit pharetra sed non. Dignissim at non hendrerit feeum est feugiat. Eu phasellus agfd pellentesque iaculis faucibqet id wfwf eget. Voxcan hendrerit fermentum est us a.......",
    //   ownerName: "Santosh G",
    //   date: "FEBRUARY 23, 2024",
    //   type: [
    //     { typeName: "TRAVEL GUIDE" },
    //     { typeName: "VEVE WQW" },

    //   ],
    //   img: "assets/blog3Img.png",
    // },
    // {
    //   head: "Learn more",
    //   pera: "Pellentesque sit scsw sfcs consetur odio id blandit pharetra sed non. Dignissim at non hendrerit feeum est feugiat. Eu phasellus agfd pellentesque iaculis faucibqet id wfwf eget. Voxcan hendrerit fermentum est us a.......",
    //   ownerName: "Santosh G",
    //   date: "FEBRUARY 23, 2024",
    //   type: [
    //     { typeName: "TRAVEL GUIDE" },
    //     { typeName: "VEVE WQW" },

    //   ],
    //   img: "assets/blog1Img.png",
    // },
    // {
    //   head: "Learn more",
    //   pera: "Pellentesque sit scsw sfcs consetur odio id blandit pharetra sed non. Dignissim at non hendrerit feeum est feugiat. Eu phasellus agfd pellentesque iaculis faucibqet id wfwf eget. Voxcan hendrerit fermentum est us a.......",
    //   ownerName: "Santosh G",
    //   date: "FEBRUARY 23, 2024",
    //   type: [
    //     { typeName: "TRAVEL GUIDE" },
    //     { typeName: "VEVE WQW" },

    //   ],
    //   img: "assets/blog2Img.png",
    // },
    // {
    //   head: "Learn more",
    //   pera: "Pellentesque sit scsw sfcs consetur odio id blandit pharetra sed non. Dignissim at non hendrerit feeum est feugiat. Eu phasellus agfd pellentesque iaculis faucibqet id wfwf eget. Voxcan hendrerit fermentum est us a.......",
    //   ownerName: "Santosh G",
    //   date: "FEBRUARY 23, 2024",
    //   type: [
    //     { typeName: "TRAVEL GUIDE" },
    //     { typeName: "VEVE WQW" },
    //   ],
    //   img: "assets/blog3Img.png",
    // },
  ];


  const partners = [
    {
      img: "assets/nvidia-inceptio-program-logo.jpg"
    },
    {
      img: "assets/ms-startups-founders-hub-logo.png"
    },
    {
      img: "assets/google-cloud-logo.png"
    },
    {
      img: "assets/fsid-iisc-logo-2.png"
    },
  ];

  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 428,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };



  const goToSlide = (index) => {
    if (sliderRef.current && index >= 0 && index < blog.length) {
      sliderRef.current.slickGoTo(index);
      setCurrentSlide(index);
    }
  };

  // const nextSlide = () => {
  //   if (sliderRef.current) {
  //     const nextIndex = (currentSlide + 1) % blog.length;
  //     sliderRef.current.slickGoTo(nextIndex);
  //     setCurrentSlide(nextIndex);
  //   }
  // };

  // const prevSlide = () => {
  //   if (sliderRef.current) {
  //     const prevIndex = (currentSlide - 1 + blog.length) % blog.length;
  //     sliderRef.current.slickGoTo(prevIndex);
  //     setCurrentSlide(prevIndex);
  //   }
  // };

  const renderPaginationNumbers = () => {
    const paginationNumbers = [];
    const totalSlides = blog.length;

    if (totalSlides <= 7) {
      // If there are 7 or fewer slides, render all pagination numbers
      for (let i = 0; i < totalSlides; i++) {
        paginationNumbers.push(i);
      }
    } else {
      // If there are more than 7 slides, render pagination numbers with ellipsis
      if (currentSlide <= 3) {
        // If current slide is within the first 4 slides
        for (let i = 0; i < 5; i++) {
          paginationNumbers.push(i);
        }
        paginationNumbers.push('...');
        paginationNumbers.push(totalSlides - 1);
      } else if (currentSlide >= totalSlides - 4) {
        // If current slide is within the last 4 slides
        paginationNumbers.push(0);
        paginationNumbers.push('...');
        for (let i = totalSlides - 5; i < totalSlides; i++) {
          paginationNumbers.push(i);
        }
      } else {
        // If current slide is in the middle
        paginationNumbers.push(0);
        paginationNumbers.push('...');
        for (let i = currentSlide - 1; i <= currentSlide + 1; i++) {
          paginationNumbers.push(i);
        }
        paginationNumbers.push('...');
        paginationNumbers.push(totalSlides - 1);
      }
    }

    return paginationNumbers.map((pageNumber, index) => (
      <span
        key={index}
        onClick={() => goToSlide(pageNumber)}
        style={{
          cursor: 'pointer',
          padding: '5px',
          margin: '0 5px',
          fontWeight: currentSlide === pageNumber ? 'bold' : 'normal',
        }}
      >
        {pageNumber === '...' ? '...' : pageNumber + 1}
      </span>
    ));
  };

  const reviews = [
    {
      name: "Srikar's Parent",
      content: 'I was pleasantly surprised by the nuances of techniques that were brought out by the analysis, and the corrections suggested. This has incredible potential for all badminton players who are keen on improving their game.',
    },
    {
      name: 'Jane Smith',
      content: 'I was pleasantly surprised by the nuances of techniques that were brought out by the analysis, and the corrections suggested. This has incredible potential for all badminton players who are keen on improving their game.',
    },
    {
      name: 'Jane Smith',
      content: 'I was pleasantly surprised by the nuances of techniques that were brought out by the analysis, and the corrections suggested. This has incredible potential for all badminton players who are keen on improving their game.',
    },
    {
      name: 'Jane Smith',
      content: 'I was pleasantly surprised by the nuances of techniques that were brought out by the analysis, and the corrections suggested. This has incredible potential for all badminton players who are keen on improving their game.',
    },
    {
      name: 'Jane Smith',
      content: 'I was pleasantly surprised by the nuances of techniques that were brought out by the analysis, and the corrections suggested. This has incredible potential for all badminton players who are keen on improving their game.',
    },
    {
      name: 'Jane Smith',
      content: 'I was pleasantly surprised by the nuances of techniques that were brought out by the analysis, and the corrections suggested. This has incredible potential for all badminton players who are keen on improving their game.',
    },
    {
      name: 'Jane Smith',
      content: 'I was pleasantly surprised by the nuances of techniques that were brought out by the analysis, and the corrections suggested. This has incredible potential for all badminton players who are keen on improving their game.',
    },
  ];

  // const experts = [
  //   { id: 1, name: "Harshit Aggarwal", image: "assets/expert1.png", ranking: "BWF World Ranking: 96", about: "Former India #1" },
  //   { id: 2, name: "Subhankar Dey", image: "assets/expert2.png", ranking: "BWF World Ranking: 37", about: "Former India #1" },
  //   { id: 3, name: "Luka", image: "assets/expert3.png", ranking: "BWF World Ranking: 52", about: "Former Austria #1" },
  //   { id: 4, name: "Siddharth Thakur", image: "assets/expert4.png", ranking: "BWF World Ranking: 120", about: "Former India #3" }
  // ]

  const review = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 428,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  // const review = {
  //   dots: false,
  //   infinite: false,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: false,
  //   speed: 2000,
  //   autoplaySpeed: 2000,
  //   cssEase: "linear",
  //   arrows: false,
  //   initialSlide: 0,
  // };

  //Button functionality
  const [isCTAClicked, setIsCTAClicked] = useState(false)
  const navigate = useNavigate();

  const upload = () => {
    setIsCTAClicked((prevState) => !prevState)
    setTimeout(() => {

      if (userRole === "player") {
        navigate('/game-posts/game-videos');
      } else if (userRole === "coach") {
        navigate('/coach_flow/pending-video');
      } else {
        dispatch(setIsLoginDialog(true))
      }

      window.scrollTo(0, 0);
      setIsCTAClicked((prevState) => !prevState)
    }, 1500)
  }
  const nextSectionRef = useRef(null);

  const scrollToNextSection = () => {
    nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleClickGetReport = () => {
    if (userRole === "player") {
      navigate('/game-posts/game-videos');
    } else if (userRole === "coach") {
      navigate('/coach_flow/pending-video');
    } else {
      dispatch(setIsLoginDialog(true))
    }
  }

  const handleClickBog = (index) => {
    navigate(`/blogdetails/${index + 1}`)
  }


  //Back Button functionality

  // useEffect(() => {
  //   const handlePopstate = (event) => {
  //     const r = window.confirm("You pressed a Back button! Are you sure?!");
  //     if (r) {
  //       navigate("/");
  //     } else {
  //       // Restore the URL to prevent the user from leaving the page
  //       window.history.pushState(null, null, window.location.pathname);
  //     }
  //   };

  //   window.addEventListener("popstate", handlePopstate);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopstate);
  //   };
  // }, [navigate]);



  return (
    <div className="homePage">

      <div className="banner">
        <marquee >We are in beta trial, If you want to participate please reach out to info@visist.ai</marquee>
      </div>

      <div className="background-video">
        <video autoPlay loop muted playsInline>
          <source src="/assets/NewHeroVideo.mp4" type="video/mp4" />
        </video>
        <div className="text-overlay">
          <div className="info">
            <h1>TRANSFORM Your Game</h1>
            <div className="horizontal_line" />
            <p>
              Transform Your Game with Instant Access to Your Training and Match Videos, Powered by Our Visual Intelligence Platform<sup>TM</sup>. Get Continuous, In-Depth Feedback That Elevates Your Performance to the Next Level!
            </p>
            <button onClick={upload} className={`ctaButton ${isCTAClicked && "ctaClicked"}`}>START FREE TRIAL {isCTAClicked && <img src="/assets/CTAAnimation.gif" />}</button>
          </div>
          <button className="arrow_dwn"
            onClick={scrollToNextSection} >{isArrowHover ? <img src="/assets/Down_arrow_Hover.svg" /> : <img src="/assets/Down_arrow_Default.svg" />}</button>
        </div>
      </div>

      <section className="Personalized_Report" ref={nextSectionRef}>
        <div className="container">
          <div className="heading">
            <h1>
              Get Your Personalized Video Report<br></br> in{" "}
              <span>3 Simple Steps</span>
            </h1>
          </div>
          {report.map((ele, index) => {
            return (
              <div className="box" key={index}>
                <div className="info_box">
                  <h4>{ele.head}<br /><p >{ele.scnSnap}</p><span className={"darkGrrenSpan"}>{ele.sanp}</span></h4>
                  <p>{ele.pera}</p>
                  <button type="button" onClick={() => {
                    navigate(ele.redirectionPath)
                    window.scrollTo(0, 0);
                  }}>{ele.btn}</button>
                </div>
                <img src={ele.img} alt="" />
              </div>
            );
          })}
        </div>
      </section >

      {/* <section className="our_experts">
        <div className="container">
          <div className="heading">
            <h1> <span>Our</span>Experts</h1>
          </div>
          <div className="hero_color"></div>
          <div className="experts_conatiner">
            {experts.map((ele) => {
              return (
                <div className="experts_box" key={ele.id}>
                  <div className="experts_top_cont">
                    <h6>{ele.name}</h6>
                    <img src={ele.image} alt={ele.name} />
                  </div>
                  <div className="experts_bottom_cont">
                    <p>{ele.ranking}</p>
                    <p>{ele.about}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section> */}

      <section className="homeBlog">
        <div className="container">
          <div className="heading">
            <h1>Blog</h1>
          </div>
          <div className="hero_color"></div>
          <Slider {...settings} ref={sliderRef} className="slider">
            {blog.map((ele, index) => (
              <div className="box" key={index} onClick={()=> handleClickBog(index)}>
                <div className="item">
                  <img src={ele.img} alt="" />
                  <div className="blg_info">
                    <h5>{ele.date}</h5>
                    <ul>
                      {ele.type.map((i, idx) => (
                        <li key={idx}>{i.typeName}</li>
                      ))}
                    </ul>
                    <h2>{ele.head}</h2>
                    <p>{ele.pera}</p>
                    <div className="ownerInfo">
                      <h3>Written by</h3>
                      <h5>{ele.ownerName}</h5>
                    </div>
                  </div>
                </div>
                <div className="readMore">
                  <NorthIcon />
                  <p>Read More</p>
                </div>
              </div>
            ))}
          </Slider>

          <div style={{ textAlign: 'center', marginTop: '10px', color: "white" }}>
            <button
              style={{ marginRight: '0px', border: "none", backgroundColor: "transparent", color: "white" }}
              onClick={() => goToSlide(currentSlide - 1)}
              disabled={currentSlide === 0}
            >
              <i className="fa-solid fa-caret-left" style={{ fontSize: "20px" }}></i>
            </button>
            {renderPaginationNumbers()}
            <button
              style={{ marginLeft: '0px', border: "none", backgroundColor: "transparent", color: "white" }}
              onClick={() => goToSlide(currentSlide + 1)}
              disabled={currentSlide === blog.length - 1}
            >
              <i className="fa-solid fa-caret-right" style={{ fontSize: "20px" }}></i>
            </button>
          </div>
          
        </div>
      </section>


      {/* <section className="review">
        <div className="heading">
          <h2>Testimonials</h2>
        </div>
        <Slider {...review}>
          {testimonials.map((review, index) => (
            <div key={index} className="review_item">
              <div className="box">
                <h3>{review.author}</h3>
                <p><span>"</span>{review.testimonialContent}</p>
              </div>
            </div>
          ))}
        </Slider>
        <div className="button">
          <button onClick={handleClickGetReport}>Get your Report</button>
        </div>
      </section> */}

      <section className="review">
        <div className="container">

          <div className="heading">
            <h2>Partners</h2>
          </div>
          <div className="image-grid">
            {partners.map((ele, index) =>

              (<img src={ele.img} className="grid-item" />)

            )}
          </div>
        </div>
        <div className="button">
          <button onClick={handleClickGetReport}>Get your Report</button>
        </div>
      </section>
    </div >
  );
}