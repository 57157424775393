import React from "react";

import './VideoTag.scss'

const VideoTag = ({ tagDetails, index }) => {


  const tagPrefix = '#';
  return (
    <span key={index}  className='video-tag'>
      {tagPrefix}{tagDetails.tagName}
    </span>
  );
};

export default VideoTag;
