import React, { useCallback, useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import styles from "../create-feedback/CreateFeedback.module.scss";
import "videojs-markers/dist/videojs.markers.css";
import "videojs-markers";
import { useDispatch, useSelector } from "react-redux";
import { getCoachFeedback } from "../../../redux/network/coachAPI";
import { Stage, Layer, Rect } from "react-konva";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from 'react-router-dom';
import {
  isStarted_GetFetchVideoContent,
  success_GetFetchVideoContent,
} from "../../../redux/reducers/videoSlice";
import { getFetchGamePostFileThumbnail } from "../../../redux/network/videoApi";
import { GET_POST_CONTENT } from "../../../config/endpoints";
import FeedbackList from "../componnets/FeedbackList";
import AnnotationStage from "../componnets/Annotations";
import VideoPlayer from "../componnets/VideojsPlayer";
import GameVideoDetails from '../../GamePost/GameVideoDetails/GameVideoDetails';

export default function ReviewFeedback() {
  const { postId: videoId } = useParams();
  const env = process.env.REACT_APP_ENV;
  const videoRef = useRef(null);
  const userId = localStorage.getItem("BQS__ID");
  const playerRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [drawing, setDrawing] = useState(false);
  
  const videoContent = useSelector((state) => state.video.videoContent);
  const jwtToken = localStorage.getItem("BQS_TOKEN");
  const [videoDimensions, setVideoDimensions] = useState({});
  const standAloneFeedbacks = useSelector(
    (state) => state.standAlone.feedbacks.feedbackData
  );
  const [annotations, setAnnotations] = useState([]);
  const firstname = localStorage.getItem("firstname");
  const lastname = localStorage.getItem("lastname");
  const [videoKey, setVideoKey] = useState(Date.now());
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const reviewPostId = localStorage.getItem('reviewPostId');
  const reviewPost = JSON.parse(localStorage.getItem(`${reviewPostId}_reviewPost`));
  const [data , setData ] = useState(reviewPost);
  const [videoThumbnails, setVideoThumbnails] = useState({});
  const thumbnailUrl =
    videoThumbnails[videoId] ||
    "https://www.fisu.net/app/uploads/2023/09/badminton.jpg";
  const dispatch = useDispatch();
  const [frameRate, setFrameRate] = useState(30);
  const [playingAudioId, setPlayingAudioId] = useState(null);
  const location = useLocation();
  const videoTagList = location.state?.videoTags || [];

  const dataFormatter = (dateString) => {
    const inputDate = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
   

    return `${day} ${monthNames[monthIndex]} ${year} ${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };
  
  const videoUrl = localStorage.getItem("videoLinkInObjectStore");
  const updatedAt = reviewPost?.updatedAt; 
  const videoTitle = videoUrl ? videoUrl.split('/').pop() : "Unknown Title";
  
  const formattedTitleDate = `${videoTitle} - ${dataFormatter(updatedAt)}`;

  useEffect(() => {
    if (data && jwtToken && env !== "standalone") {
      const fetchThumbnail = async (reviewPostId, tnPath) => {
        try {
          const thumbnailUrl = await getFetchGamePostFileThumbnail(
            jwtToken,
            reviewPostId,
            tnPath
          );
          setVideoThumbnails((prev) => ({
            ...prev,
            [reviewPostId]: thumbnailUrl,
          }));
        } catch (error) {
          console.error("Error fetching thumbnail:", error);
        }
      };

      fetchThumbnail(data._id, data.game[0]?.tnPath);

    }
  }, [data, jwtToken, userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = localStorage.getItem("videoLinkInObjectStore");

        dispatch(isStarted_GetFetchVideoContent());

        const response = await fetch(url, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch video");
        }

        const reader = response.body.getReader();
        const stream = new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });

        const responseBlob = await new Response(stream).blob();
        const file = new File([responseBlob], "video.mp4", {
          type: "video/mp4",
        });
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          const res = fr.result;
          dispatch(success_GetFetchVideoContent(res));
          setVideoLoaded(true);
          setVideoKey(Date.now()); // Mark the video as loaded
          // Play the video only when the data is loaded
        });
      } catch (error) {
        // dispatch(error_GetFetchVideoContert(error.message || "Error while fetching video"));
        console.error("Error fetching video:", error);
      }
    };
    fetchData();
  }, [videoId, dispatch, jwtToken]);

  const userRole = localStorage.getItem("BQS_ROLE");

  const handleAudioPlay = (audioElement, annotation) => {
    // Pause video if playing
    if (playerRef.current) {
      playerRef.current.pause();
    }

    // Pause any other playing audio
    document.querySelectorAll('audio').forEach(audio => {
      if (audio !== audioElement) {
        audio.pause();
      }
    });

    setPlayingAudioId(annotation._id);

    // Add ended event listener to reset playingAudioId
    audioElement.addEventListener('ended', () => {
      setPlayingAudioId(null);
    });
  };

  return (
    <section className={styles.feedback_video}>
      {userRole !== "player" ? (
    <h1>
      {firstname || ""} {lastname || ""}
      <span> @{dataFormatter(reviewPost?.updatedAt)}</span>
    </h1>
  ) : (
    <h1>{formattedTitleDate}</h1>
  )}
      <div className={styles.video_section}>
        <div className={styles.video_container}>
          <VideoPlayer
            videoId={videoId}
            currentTime={currentTime}
            videoLoaded={videoLoaded}
            videoContent={videoContent}
            annotations={annotations}
            setAnnotations={setAnnotations}
            setLoading={setLoading}
            setVideoLoaded={setVideoLoaded}
            setVideoKey={setVideoKey}
            setFrameRate={setFrameRate}
            setCurrentTime={setCurrentTime}
            setVideoDimensions={setVideoDimensions}
            thumbnailUrl={thumbnailUrl}
            playerRef={playerRef}
            videoRef={videoRef}
            standAloneFeedbacks={standAloneFeedbacks}
            frameRate={frameRate}
            isPaused={isPaused}
            getCoachFeedback={getCoachFeedback}
            videoKey={videoKey}
          />

          <AnnotationStage
            videoDimensions={videoDimensions}
            annotations={annotations}
            isPaused={isPaused}
            playerRef={playerRef}
          />
        </div>

        <div className={styles.sidebar}>
          <FeedbackList
            annotations={annotations}
            videoContent={videoContent}
            handleAudioPlay={handleAudioPlay}
            currentTime={currentTime}
          />
        </div>
      </div>
      <div>
      { videoTagList.length > 0 && <GameVideoDetails videoTagList={videoTagList}/> }
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </section>
  );
}
