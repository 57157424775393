import React from "react";

import './VideoTagSelect.scss'

const VideoTagSelect = ({ tagDetails, index, sendTagForRemoval }) => {



  return (
    <span key={index}  className='video-tag'>
      {tagDetails.tagName}
      <button className='cancelButton' onClick={() => sendTagForRemoval(tagDetails._id)} >
       x
      </button>
    </span>
  );
};

export default VideoTagSelect;
