import React, { useCallback, useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-markers/dist/videojs.markers.css";
import "videojs-markers";
import { Stage, Layer, Rect } from "react-konva";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { changeVideoPostStatus, createUpdateFeedback, getCoachFeedback, } from "../../../redux/network/coachAPI";
import { addStandaloneFeedback } from "../../../redux/reducers/standaloneSlice";
import { updateStandAloneVideoStatusFunction } from "../../../redux/network/standAloneApi";
import styles from "./CreateFeedback.module.scss";
import { error_GetFetchVideoContent, isStarted_GetFetchVideoContent, success_GetFetchVideoContent, } from "../../../redux/reducers/videoSlice";
import { getFetchGamePostFileThumbnail } from "../../../redux/network/videoApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faCheck,
  faXmark,
  faBolt
} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { v4 as uuidv4 } from "uuid";
import { FaMicrophone, FaTrash as FaTrashIcon } from "react-icons/fa";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import FeedbackList from "../componnets/FeedbackList";
import AnnotationStage from "../componnets/Annotations";
import VideoPlayer from "../componnets/VideojsPlayer";
import jsPDF from "jspdf";
import { useLocation } from 'react-router-dom';
import GameVideoDetails from '../../GamePost/GameVideoDetails/GameVideoDetails';

const CreateFeedback = () => {
  const { postId: videoId } = useParams();
  const env = process.env.REACT_APP_ENV;
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [comment, setComment] = useState("");
  const [newAnnotation, setNewAnnotation] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [drawing, setDrawing] = useState(false);
  const navigate = useNavigate();
  const videoContent = useSelector((state) => state.video.videoContent);
  const dispatch = useDispatch();
  const [videoDimensions, setVideoDimensions] = useState({width: 0,
    height: 0,
  });
  const standAloneFeedbacks = useSelector((state) => state.standAlone.feedbacks.feedbackData);
  const [annotations, setAnnotations] = useState([]);
  const [onceTimeAnnotaions, setOnceTimeAnnotaions] = useState([]);
  const [audioComment, setAudioComment] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const maxRecordingDuration =
    parseInt(process.env.REACT_APP_MAX_RECORDING_DURATION) || 4; // Default to 4 seconds if not set
  const ffmpeg = new FFmpeg();
  const maxAudioFeedbacks = parseInt(process.env.REACT_APP_MAX_AUDIO_FEEDBACKS) || 2; // Default to 2 if not set
  const [mediaStream, setMediaStream] = useState(null); // Add this new state

  const [expandedCommentId, setExpandedCommentId] = useState(null); // Track the expanded comment ID
  const controlBarHeight = 25;
  const userId = localStorage.getItem("BQS__ID");
  const jwtToken = localStorage.getItem("BQS_TOKEN");
  const firstname = localStorage.getItem("firstname");
  const lastname = localStorage.getItem("lastname");
  const reviewPostId = localStorage.getItem('reviewPostId');
  const reviewPost = JSON.parse(localStorage.getItem(`${reviewPostId}_reviewPost`));
  const videoUrl = localStorage.getItem("videoLinkInObjectStore");
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
    const [data , setData] = useState(reviewPost);
  const [frameRate, setFrameRate] = useState(30);
  const [videoThumbnails, setVideoThumbnails] = useState({});
  const thumbnailUrl =
    videoThumbnails[videoId] ||
    "https://www.fisu.net/app/uploads/2023/09/badminton.jpg";
  const [videoKey, setVideoKey] = useState(Date.now()); // Force re-render video element
  const [isLoading, setIsLoading] = useState(false); // State for the loader
  const konvaRef = useRef(null);
  const location = useLocation();
  const videoTagList = location.state?.videoTags || [];
  // console.log("frameRate===", frameRate);
  const dataFormatter = (dateString) => {
    const inputDate = new Date(dateString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    return `${day} ${monthNames[monthIndex]} ${year} ${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  };
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [annotationToDelete, setAnnotationToDelete] = useState(null);
  const [isSaving, setIsSaving] = useState(false); // Add this new state
  const [playingAudioId, setPlayingAudioId] = useState(null);
  const [pollAiFeedbackState, setPollAiFeedbackState] = useState(false);
  const GENERATE_AI_FEEDBACK_BUTTON_STATE = ["Get AI Feedback", "Getting AI Feedback", "Got AI Feedback"];
  const [isGeneratingFeedback, setIsGeneratingFeedback] = 
        useState(location.state?.aiFeedbackState === 'review_requested' ?  true : false);
  
  const AI_FEEDBACK_CREATED = "created";
  const AI_FEEDBACK_REVIEW_REQUESTED = "review_requested";
  const AI_FEEDBACK_REVIEWED = "reviewed";
  const POLLING_INTERVAL_IN_SECS = 1000;
  const STOP_POLLING_AFTER_SECS = 13000;
  const [aiFeedbackState, setAiFeedbackState] = useState(location.state?.aiFeedbackState);
  const RELOADED_PAGE_STR = "reload";
  const extractTitle = (url) => {
    if (!url) return "Unknown Title";
    return url.split("/").pop().split(".")[0]; // Extract filename without extension
  };
  const videoTitle = extractTitle(videoUrl);
  localStorage.setItem("videoTitle", videoTitle);


  const handleGenerateAIFeedback = () => {
    // Logic to generate AI feedback
    toast.info("AI Feedback generation is not implemented yet.");
  };

  useEffect(() => {
    console.log("loading..............", data);
    if (data && jwtToken && env !== "standalone") {
      console.log("loading 2..............");
      const fetchThumbnail = async (reviewPostId, tnPath) => {
        try {
          const thumbnailUrl = await getFetchGamePostFileThumbnail(
            jwtToken,
            reviewPostId,
            tnPath
          );
          setVideoThumbnails((prev) => ({
            ...prev,
            [reviewPostId]: thumbnailUrl,
          }));
        } catch (error) {
          console.log("Error fetching thumbnail:", error);
        }
      };
    
      fetchThumbnail(data._id , data.game[0]?.tnPath);
    }
  }, [data, jwtToken, userId, env]); 

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const url = localStorage.getItem("videoLinkInObjectStore");
        dispatch(isStarted_GetFetchVideoContent());

        const response = await fetch(url, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch video");
        }

        const reader = response.body.getReader();
        const stream = new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });

        const responseBlob = await new Response(stream).blob();
        const file = new File([responseBlob], "video.mp4", {
          type: "video/mp4",
        });
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener("load", () => {
          const res = fr.result;
          dispatch(success_GetFetchVideoContent(res));
          setVideoLoaded(true);
          setVideoKey(Date.now()); 
        });
      } catch (error) {
        dispatch(
          error_GetFetchVideoContent(
            error.message || "Error while fetching video"
          )
        );
        console.error("Error fetching video:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [videoId, dispatch, jwtToken]);

  const handleSaveAnnotation = async () => {
    
    if (!comment.trim() && !audioComment) {
      toast.error("Please provide a comment or record an audio comment.");
      return;
    }
    setIsLoading(true);
    try {
      setEditingCommentId(null);
      setEditedCommentText("");

      if (
        !videoDimensions ||
        !videoDimensions.width ||
        !videoDimensions.height
      ) {
        toast.error("Annotation area or video dimensions are invalid.");
        setIsLoading(false);
        return;
      }

      const relativeCoordinates = {
        x1: newAnnotation?.x / videoDimensions?.width,
        y1: newAnnotation?.y / videoDimensions?.height,
        x2: (newAnnotation?.x + newAnnotation?.width) / videoDimensions?.width,
        y2:
          (newAnnotation?.y + newAnnotation?.height) / videoDimensions?.height,
      };

      const currentTimes = playerRef?.current?.currentTime();

      // Create array to hold comments
      const commentsToAdd = [];

      // Add text comment if present
      if (comment.trim()) {
        commentsToAdd.push({
          _id: uuidv4(), // Generate unique ID for each comment
          body: comment.trim(),
          metaData: {
            dateTime: new Date().toISOString(),
            userId,
            userName: "coach16",
          },
          type: "text",
        });
      }
      if (audioComment) {
        commentsToAdd.push({
          _id: uuidv4(), // Generate unique ID for each comment
          body: audioComment,
          metaData: {
            dateTime: new Date().toISOString(),
            userId,
            userName: "coach16",
          },
          type: "audio",
        });
      }
      // Create separate annotation entries for each comment
      const newAnnotations = commentsToAdd.map((commentData, index) => ({
        _id: annotations.length + index + 1,
        timeRange: { startTime: currentTimes, endTime: currentTimes },
        markerDetails: { coordinates: relativeCoordinates },
        comments: [commentData],
      }));

      const updatedAnnotations = [...annotations, ...newAnnotations];
      setAnnotations(updatedAnnotations);
      saveFeedback(updatedAnnotations); 
      toast.success("Feedback submitted successfully!");
      // Add markers to the player
      if (playerRef.current && playerRef.current.markers) {
        const markers = updatedAnnotations.map((annotation) => ({
          time: annotation.timeRange.startTime,
          text: annotation?.comments?.[0]?.body.startsWith("data:audio")
            ? "Audio"
            : annotation?.comments?.[0]?.body || "Annotation", // Default text if no comment exists
        }));
        playerRef.current.markers.add(markers); // Add updated markers
      }
    } catch (error) {
      console.error("Error saving annotation:", error);
      toast.error("Failed to save annotation. Please try again.");
    } finally {
      setComment("");
      setAudioComment(null);
      setNewAnnotation(null);
      setIsLoading(false);
    }
  };

  const saveFeedback = async (updatedAnnotations) => {
    if (!updatedAnnotations) {
      toast.error("Please add Makers and commnets");
      return;
    }
    setIsSaving(true); // Show loader when starting to save
    try {
      const feedbackData = updatedAnnotations.map((annotation) => ({
        ...(env === "standalone" ? { _id: annotation._id } : {}),
        timeRange: annotation.timeRange,
        markerDetails: {
          coordinates: {
            x1: annotation.markerDetails.coordinates.x1 || 0 ,
            y1: annotation.markerDetails.coordinates.y1 || 0,
            x2: annotation.markerDetails.coordinates.x2 || 0,
            y2: annotation.markerDetails.coordinates.y2 || 0,
          },
        },
        comments: annotation.comments.map((comment) => ({
          metaData: comment.metaData,
          body: comment.body,
        })),
      }));

      if (env === "standalone") {
        const standAloneFeedback = {
          _id: videoId,
          feedback: feedbackData,
        };
        dispatch(addStandaloneFeedback(standAloneFeedback));
        dispatch(updateStandAloneVideoStatusFunction(videoId));
        return null;
      }

      await createUpdateFeedback(jwtToken, feedbackData, videoId);
    } catch (error) {
      console.error("Error while submitting feedback:", error);
      toast.error(error.message || "Error while submitting feedback");
    } finally {
      setIsSaving(false); // Hide loader when done
    }
  };

  const backNavigation = () => {
    navigate("/coach_flow/pending-video");
  };

  const submitData = async () => {
    if (!annotations) {
      toast.error("Please add Makers and commnets");
      return;
    }

    if (env === "standalone") {
      dispatch(updateStandAloneVideoStatusFunction("reviewed", videoId));
      navigate("/coach_flow/reviewd-video");

      return null;
    }
    try {
      await changeVideoStatus();
      toast.success("Feedback submitted successfully!");
      navigate("/coach_flow/reviewd-video");
    } catch (error) {
      console.error("Error while submitting feedback:", error);
      toast.error(error.message || "Error while submitting feedback");
    }
  };

  const changeVideoStatus = async () => {
    const formData = { state: "REVIEWED" };
    try {
      await changeVideoPostStatus(jwtToken, formData, videoId);
    } catch (error) {
      toast.error(
        error.message ||
        "An error occurred while updating the video status. Please try again."
      );
    }
  };

  const handlePauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      // TODO:check this line where is used
      setIsPaused(true);
    }
  };

  const handlePlayVideo = () => {
    // Pause any playing audio
    document.querySelectorAll('audio').forEach(audio => {
      audio.pause();
    });
    setPlayingAudioId(null);

    if (playerRef.current) {
      playerRef.current.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsPaused(false);
    } else {
      console.error("Video reference is not yet ready.");
    }
  };

  const handleVideoEnd = () => {
    setIsPaused(false); // Set paused to false when the video ends
  };

  const handleUpdateComment = async (annotation) => {
    try {
      if (!editedCommentText.trim()) {
        toast.error("Comment cannot be empty");
        return;
      }

      // Create updated annotation with new comment
      const updatedAnnotation = {
        ...annotation,
        comments: [
          {
            _id: uuidv4(), // Generate unique ID for each comment
            ...annotation.comments[0],
            body: editedCommentText.trim(),
            metaData: {
              ...annotation.comments[0].metaData,
              dateTime: new Date().toISOString(),
            },
          },
        ],
      };

      // Update annotations array
      const updatedAnnotations = annotations.map((a) =>
        a._id === annotation._id ? updatedAnnotation : a
      );

      // Update local state
      setAnnotations(updatedAnnotations);
      setOnceTimeAnnotaions(updatedAnnotations);

      // Save to backend
      await saveFeedback(updatedAnnotations);

      // Reset edit state
      setEditingCommentId(null);
      setEditedCommentText("");

      toast.success("Comment updated successfully");
    } catch (error) {
      console.error("Error updating comment:", error);
      toast.error("Failed to update comment. Please try again.");
    }
  };

  const handleDeleteClick = (annotation) => {
    if (playerRef.current) {
      playerRef.current.pause();
    }
    setAnnotationToDelete(annotation);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    if (playerRef.current) {
      playerRef.current.play();
    }
    setDeleteDialogOpen(false);
    setAnnotationToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (!annotationToDelete) return;
    if (playerRef.current) {
      playerRef.current.play();
    }
    setDeleteDialogOpen(false);
    setIsDeleting(true);

    try {
      // Filter out the annotation with the matching ID
      const updatedAnnotations = annotations.filter(
        (a) => a._id !== annotationToDelete._id
      );

      // Update local state
      setAnnotations(updatedAnnotations);
      setOnceTimeAnnotaions(updatedAnnotations);

      // Update markers
      if (playerRef.current) {
        // First destroy existing markers
        playerRef.current.markers.destroy();

        // Reinitialize markers plugin
        playerRef.current.markers({
          markerTip: {
            display: true,
            text: (marker) => marker.text,
          },
          markerStyle: {
            width: "5px",
            height: "20px",
            backgroundColor: "red",
            cursor: "pointer",
            borderRadius: "1px",
            bottom: "-8px",
          },
          markers: [], // Initialize with empty array
        });

        // Only add markers if there are annotations
        if (updatedAnnotations.length > 0) {
          const markers = updatedAnnotations.map((annotation) => ({
            time: annotation.timeRange.startTime,
            text: annotation?.comments?.[0]?.body.startsWith("data:audio")
              ? "Audio"
              : annotation?.comments?.[0]?.body || "Annotation", // Default text if no comment exists
          }));

          // Add new markers
          playerRef.current.markers.add(markers);
        }
      }

      // Save to backend
      await saveFeedback(updatedAnnotations);

      toast.success("Comment deleted successfully");
    } catch (error) {
      console.error("Error deleting comment:", error);
      toast.error("Failed to delete comment. Please try again.");
    } finally {
      setIsDeleting(false);
      setAnnotationToDelete(null);
    }
  };

  const handleStartRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          setMediaStream(stream); // Store the stream
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          const chunks = [];

          recorder.ondataavailable = (event) => {
            chunks.push(event.data);
          };

          recorder.onstop = async () => {
            stopMediaTracks(); // Stop media tracks after recording
            // Only process the audio if recording wasn't stopped due to timeout
            if (!recorder.exceededMaxDuration) {
              setAudioChunks(chunks);
              const audioBlob = new Blob(chunks, { type: "audio/wav" });
              const base64MP3 = await convertWavToMp3(audioBlob);
              setAudioComment(base64MP3);
            }
          };

          recorder.start();
          setIsRecording(true);

          // Stop recording after maxRecordingDuration seconds
          setTimeout(() => {
            if (recorder.state === "recording") {
              recorder.exceededMaxDuration = true; // Mark that we stopped due to timeout
              recorder.stop();
              setIsRecording(false);
              setAudioComment(null);
              setAudioChunks([]);
              toast.error(
                `Please record the audio again under the duration of ${maxRecordingDuration} seconds.`
              );
            }
          }, (maxRecordingDuration + 1) * 1000);
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
          toast.error("Error accessing microphone.");
        });
    } else {
      toast.error("Audio recording is not supported in this browser.");
    }
  };

  const stopMediaTracks = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => {
        track.stop();
      });
      setMediaStream(null);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
      setIsRecording(false);
      stopMediaTracks(); // Stop media tracks after recording
    }
  };

  const convertWavToMp3 = async (audioBlob) => {
    if (!ffmpeg.loaded) {
      await ffmpeg.load();
    }

    // Convert Blob to ArrayBuffer
    const fileData = await audioBlob.arrayBuffer();

    // Write WAV file to FFmpeg's virtual filesystem
    await ffmpeg.writeFile("input.wav", new Uint8Array(fileData));

    // Convert WAV to MP3 (64kbps)
    await ffmpeg.exec(["-i", "input.wav", "-b:a", "64k", "output.mp3"]);

    // Read the MP3 file from FFmpeg's filesystem
    const mp3Data = await ffmpeg.readFile("output.mp3");

    // Convert MP3 file to a Blob
    const mp3Blob = new Blob([mp3Data], { type: "audio/mp3" });

    // Convert Blob to Base64
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(mp3Blob);
      reader.onloadend = () => resolve(reader.result);
    });
  };

  const handleDeleteAudio = () => {
    setAudioComment(null);
    setAudioChunks([]);
  };

  const handleAudioPlay = (audioElement, annotation) => {
    // Pause video if playing
    if (playerRef.current) {
      playerRef.current.pause();
    }

    // Pause any other playing audio
    document.querySelectorAll('audio').forEach(audio => {
      if (audio !== audioElement) {
        audio.pause();
      }
    });

    setPlayingAudioId(annotation._id);

    // Add ended event listener to reset playingAudioId
    audioElement.addEventListener('ended', () => {
      setPlayingAudioId(null);
    });
  };

  const downloadPDF = async () => {
    if (newAnnotation || isRecording) {
      setComment("");
      setAudioComment(null);
      setNewAnnotation(null);
      setIsLoading(false);
    }

    try {
      setIsLoading(true);
      const doc = new jsPDF("p", "pt", "a4");
      const margins = {
        top: 40,
        bottom: 40,
        left: 40,
        right: 40,
      };
      const pageWidth =
        doc.internal.pageSize.getWidth() - margins.left - margins.right;
      const pageHeight = doc.internal.pageSize.getHeight();

      // Add title and metadata
      let yPosition = margins.top;
      doc.setFontSize(20);
      doc.text("Video Feedback Report", margins.left, yPosition);
      yPosition += 40;

    
      doc.setFontSize(12);
      doc.text(`Player: ${firstname} ${lastname}`, margins.left, yPosition);
      yPosition += 20;
      doc.text(
        `Date: ${dataFormatter(reviewPost?.updatedAt)}`,
        margins.left,
        yPosition
      );
      yPosition += 40;

      // Group annotations by timestamp
      const groupedAnnotations = annotations.reduce((groups, annotation) => {
        const time = annotation.timeRange.startTime;
        if (!groups[time]) {
          groups[time] = [];
        }
        groups[time].push(annotation);
        return groups;
      }, {});

      // Sort timestamps
      const sortedTimestamps = Object.keys(groupedAnnotations).sort(
        (a, b) => parseFloat(a) - parseFloat(b)
      );

      for (const timestamp of sortedTimestamps) {
        const annotationsAtTime = groupedAnnotations[timestamp];
        const imgHeight = (pageWidth * 9) / 16;
        const textHeight = 100;
        const totalHeight = imgHeight + textHeight + 60;

        if (yPosition + totalHeight > pageHeight - margins.bottom) {
          doc.addPage();
          yPosition = margins.top;
        }

        // Add timestamp
        doc.setFontSize(14);
        doc.setTextColor(0, 102, 51);
        doc.text(
          `Timestamp: ${parseFloat(timestamp).toFixed(2)}s`,
          margins.left,
          yPosition
        );
        yPosition += 25;

        // Set video time and wait for frame to load
        if (playerRef.current) {
          playerRef.current.pause();
          playerRef.current.currentTime(parseFloat(timestamp));

          // Wait for frame to be ready
          await new Promise((resolve) => {
            playerRef.current.one("seeked", async () => {
              await new Promise((r) => setTimeout(r, 100));

              // Create canvas with all annotations for this timestamp
              const canvas = document.createElement("canvas");
              const videoElement = playerRef.current.tech().el();
              const videoWidth = videoElement.videoWidth;
              const videoHeight = videoElement.videoHeight;
              canvas.width = videoWidth;
              canvas.height = videoHeight;
              const ctx = canvas.getContext("2d");

              // Draw video frame
              ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);

              // Draw all annotations for this timestamp
              annotationsAtTime.forEach((annotation) => {
                if (annotation.markerDetails.coordinates) {
                  const coords = annotation.markerDetails.coordinates;
                  ctx.strokeStyle = "yellow";
                  ctx.lineWidth = 6;

                  const x = coords.x1 * videoWidth;
                  const y = coords.y1 * videoHeight;
                  const width = (coords.x2 - coords.x1) * videoWidth;
                  const height = (coords.y2 - coords.y1) * videoHeight;

                  ctx.fillStyle = "rgba(255, 255, 0, 0)";
                  ctx.fillRect(x, y, width, height);
                  ctx.strokeRect(x, y, width, height);
                }
              });

              const frameImage = canvas.toDataURL("image/jpeg", 1.0);
              try {
                doc.addImage(
                  frameImage,
                  "JPEG",
                  margins.left,
                  yPosition,
                  pageWidth,
                  imgHeight,
                  "",
                  "FAST"
                );
                yPosition += imgHeight + 20;
              } catch (imgError) {
                console.error("Error adding image to PDF:", imgError);
              }
              resolve();
            });
          });
        }

        // Add feedback text for all annotations at this timestamp
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);

        for (const annotation of annotationsAtTime) {
          const feedback = annotation.comments[0].body;
          if (feedback.startsWith("data:audio")) {
            doc.text(
              "[Please visit the Visist Portal to listen to the audio feedbacks]",
              margins.left,
              yPosition
            );
            yPosition += 20;
          } else {
            const lines = doc.splitTextToSize(
              "Feedback: " + feedback,
              pageWidth
            );
            doc.text(lines, margins.left, yPosition);
            yPosition += lines.length * 15 + 30;
          }
        }

        yPosition += 20;
      }

      doc.save("video-feedback.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Error generating PDF. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className={styles.feedback_video}>
            <h1>
               {localStorage.getItem("videoTitle") || "Unknown Title"} -
                {firstname || ""} {lastname || ""}
               <span> @{dataFormatter(reviewPost?.updatedAt)}</span>
            </h1>
        <div className={styles.video_section}>
          <div className={styles.video_container}>
            {!videoLoaded && (
              <div className={styles.loader}>
                <div className={styles.spinner}></div>
              </div>
            )}
            <div className={styles.video_controls}>
              <VideoPlayer
                videoId={videoId}
                currentTime={currentTime}
                videoLoaded={videoLoaded}
                videoContent={videoContent}
                annotations={annotations}
                setAnnotations={setAnnotations}
                setOnceTimeAnnotaions={setOnceTimeAnnotaions}
                setLoading={setLoading}
                setVideoLoaded={setVideoLoaded}
                setVideoKey={setVideoKey}
                setFrameRate={setFrameRate}
                setCurrentTime={setCurrentTime}
                setVideoDimensions={setVideoDimensions}
                thumbnailUrl={thumbnailUrl}
                playerRef={playerRef}
                videoRef={videoRef}
                setEditingCommentId={setEditingCommentId}
                handlePauseVideo={handlePauseVideo}
                handlePlayVideo={handlePlayVideo}
                handleVideoEnd={handleVideoEnd}
                standAloneFeedbacks={standAloneFeedbacks}
                setEditedCommentText={setEditedCommentText}
                frameRate={frameRate}
                isPaused={isPaused}
                addStandaloneFeedback={addStandaloneFeedback}
                getCoachFeedback={getCoachFeedback}
                videoKey={videoKey}
                env={env}
              />
            </div>

            <AnnotationStage
              videoDimensions={videoDimensions}
              annotations={annotations}
              newAnnotation={newAnnotation}
              setNewAnnotation={setNewAnnotation}
              drawing={drawing}
              setDrawing={setDrawing}
              playerRef={playerRef}
              isPaused={isPaused}
            />
          </div>

          <div className={styles.sidebar}>
            <button
              className={styles.generate_ai_feedback_button}
              onClick={handleGenerateAIFeedback}
              disabled={true}
            >
              <FontAwesomeIcon icon={faBolt} style={{ marginRight: "8px" }} />
              Generate AI Feedback
            </button>
            <h4>Add Feedback</h4>
            <div className={styles.feedback_input_container}>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Feedback"
                style={{ fontWeight: 'bold' }}
              />
              <button
                className={`${styles.audio_button} ${isRecording ? styles.recording : ""
                  }`}
                onClick={
                  isRecording ? handleStopRecording : handleStartRecording
                }
              >
                <FaMicrophone />
              </button>
            </div>
            {audioComment && (
              <div className={styles.audio_preview}>
                <audio
                  controls
                  src={audioComment}
                  onPlay={(e) => handleAudioPlay(e.target, { _id: 'preview' })}
                />
                <button
                  className={styles.delete_button}
                  onClick={handleDeleteAudio}
                >
                  <FaTrashIcon />
                </button>
              </div>
            )}
            <button
              className={styles.save_button}
              onClick={handleSaveAnnotation}
              disabled={!comment.trim() && !audioComment} // Disable when no comment or audio
            >
              {isLoading ? (
                <div className={styles.loader}>
                  <div className={styles.spinner}></div>
                </div>
              ) : (
                "Save Feedback"
              )}
            </button>
            <FeedbackList
              annotations={annotations}
              videoContent={videoContent}
              handleDeleteClick={handleDeleteClick}
              handleAudioPlay={handleAudioPlay}
              handleUpdateComment={handleUpdateComment}
              editingCommentId={editingCommentId}
              setEditingCommentId={setEditingCommentId}
              editedCommentText={editedCommentText}
              setEditedCommentText={setEditedCommentText}
              handleDeleteConfirm={handleDeleteConfirm}
              handleDeleteCancel={handleDeleteCancel}
              handleDeleteAudio={handleDeleteAudio}
              isDeleting={isDeleting}
              isSaving={isSaving}
              currentTime={currentTime}
            />
          </div>
        </div>
              
        { videoTagList.length > 0 && <GameVideoDetails videoTagList={videoTagList}/> }

        <div className={styles.actions}>
          <button className={styles.cancel} onClick={backNavigation}>
            Cancel
          </button>
          <button
            className={styles.download}
            onClick={downloadPDF}
            disabled={true}
          >
            Download Report
          </button>
          <button
            disabled={
              annotations.length === 0 || videoContent.isStartedvideoContent
            }
            className={styles.submit}
            onClick={submitData}
          >
            Publish Report
          </button>
        </div>
      </section>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Feedback"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this feedback? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Full Page Loader */}
      {(isDeleting || isSaving) && (
        <div className={styles.fullPageLoader}>
          <div className={styles.spinner}></div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default CreateFeedback;
