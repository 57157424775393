import React, { useState, useEffect } from 'react';
import styles from "./Pending.module.scss"
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress } from '@mui/material'; // Material UI Dialog components
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from 'react-redux';
import { getAllPostCounts, getFetchGamePostFileThumbnail, getVideos } from '../../../redux/network/videoApi';
import thumbnail from './thumbnail.jpg';
import { useNavigate } from 'react-router-dom';
import SearchVideoWithTagName from "../../SearchBoxes/SearchVideoWithTagName";

const dataFormatter = (dateString) => {
    const inputDate = new Date(dateString);

    const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ];

    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    return `${day} ${monthNames[monthIndex]} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}


const extractTitle = (videoPath) => {
    if (!videoPath) return "Unknown Title";  
    const filename = videoPath.split('/').pop().replace(/\.[^/.]+$/, ""); 
    return filename 
};
const truncateTitle = (filename) => {
    if (!filename) return "";
    const words = filename.split(" ");
    return words.length > 6 ? words.slice(0, 6).join(" ") + " .." : filename;
};

const extractPlayerName = (video) => {
    if (!video || !video.poster || !video.poster.profileData || !video.poster.profileData.name) {
        return "Unknown";  // Handle missing values safely
    }

    const { firstName = "",  lastName = "" } = video.poster.profileData.name;
    return `${firstName}  ${lastName}`.trim(); 
};

const Pending = () => {
    const env = process.env.REACT_APP_ENV;
    const dispatch = useDispatch();
    const jwtToken = localStorage.getItem("BQS_TOKEN");
    const userId = localStorage.getItem("BQS__ID");
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [page, setPage] = useState(1); // State to manage the current page
    const [totalPages, setTotalPages] = useState(1); // Total pages from the response
    const [sortOption, setSortOption] = useState('default');
    const [avoidUpdateTrigger, setAvoidUpdateTrigger] = useState(false);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const [videoThumbnails, setVideoThumbnails] = useState({});
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);

    const postCountData = useSelector(state => state.video.videosCount);
    const COUNT = Math.ceil(postCountData?.data?.count / limit)

    const videocoutn = postCountData?.data?.count
    const [searchTerm, setSearchTerm] = useState("");


    //All Posts Count
    useEffect(() => {
        if (jwtToken && userId && !avoidUpdateTrigger && env !== "standalone") {
            dispatch(getAllPostCounts(jwtToken, {
                "condition": {
                    "state": ["review_requested"]
                },

            }));
        }
    }, [jwtToken, userId, page, sortOption, updateTrigger, skip, limit]);


    //All Posts Details
    useEffect(() => {

        if (jwtToken && userId && !avoidUpdateTrigger && env !== "standalone") {
            dispatch(getVideos(jwtToken, {
                "condition": {
                    "state": ["review_requested"]
                },
                "sort": {
                    "updatedAt": -1
                },
                "skip": skip,
                "limit": limit,
            }));
        }
    }, [jwtToken, userId, page, sortOption, skip, limit]);

    const handleChange = (event, value) => {
        setPage(value);
        setSkip((value - 1) * limit);
        window.scrollTo(0, 0);
    };;

    const openModal = (video) => {

        setSelectedVideo(video);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedVideo(null);
        setIsModalOpen(false);
    };

    const handleClickDel = async (video) => {
        try {
            const response = await fetch(`http://localhost:3000/api/videos/${video._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                // On success, remove the specific video from the state
                setVideos(prevVideos => prevVideos.filter(v => v._id !== video._id));
                toast.success('Video deleted successfully');
            } else {
                toast.error('Error deleting video');
            }
        } catch (error) {
            toast.error('Request failed', error);
        }
    };

    let { isStarted, isError, error, data } = useSelector(state => state.video.video);

    const firstname = data.filter(n => n.poster?.profileData?.name?.firstName)
    console.log("firstname-----------", firstname)

    let filteredVideos = [];
    if (env === "standalone") {
        filteredVideos = data.filter(video => video.state === "review_requested"); // Update the outer variable
        console.log("filteredVideos", filteredVideos); // This should now reflect correctly
    }


    useEffect(() => {
        if (data?.length > 0 && jwtToken && env !== "standalone") {
            const fetchThumbnail = async (reviewPostId, tnPath) => {
                try {
                    const thumbnailUrl = await getFetchGamePostFileThumbnail(jwtToken, reviewPostId, tnPath);
                    setVideoThumbnails(prev => ({ ...prev, [reviewPostId]: thumbnailUrl }));
                } catch (error) {
                    console.error('Error fetching thumbnail:', error);
                }
            };
            data?.forEach((item) => {
                fetchThumbnail(item._id, item.game[0]?.tnPath);
            });
        }
    }, [data, jwtToken, userId]);


    const navigate = useNavigate();

    async function handlePlayClick(video) {
        const postId = video._id;
        const videoLinkInObjectStore = video.game[0].path;
        setSelectedVideo(video); // Set the selected video state
        navigate(`/coach_flow/pending-video/feedback/${postId}`, { state: { videoTags: video.tags } });
        console.log("postId==", postId);
        localStorage.setItem("reviewPostId", postId);
        localStorage.setItem(`${postId}_reviewPost`, JSON.stringify(video));
        localStorage.setItem("videoLinkInObjectStore", videoLinkInObjectStore);

        if (env !== "standalone") {
            const { firstName, lastName } = video?.poster?.profileData?.name || {};
            localStorage.setItem("firstname", firstName || "");
            localStorage.setItem("lastname", lastName || "");
            console.log("First Name:", firstName, "Last Name:", lastName);
        }
        if (env === "standalone") {
            return null;
        }
    }

    const setSearchText = (searchText) => {
        console.log("setting searchterm" ,searchText);
        setSearchTerm(searchText);
        console.log("setting searchterm 1" , searchTerm);
    };

    return (
        <div>
            {/* Thumbnail Grid */}
            <div className={styles.filters}>
                <h1>Video Queue</h1>
            </div>

            <SearchVideoWithTagName setSearchText={setSearchText}/>

            <div className={styles.video_section}>
                {/* <h1>Video Queue</h1> */}
                {isStarted ?
                    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "25vh" }}>
                        <CircularProgress /> <p>Loading....</p>
                    </Box>

                    :
                    <div className={styles.wrp_video}>
                        {(env === "standalone" ? filteredVideos.length : data.length) > 0 ? (
                            (env === "standalone" ? filteredVideos : data)?.filter(video => 
                                ((searchTerm.length === 0) || video?.tags.some(tag => tag.tagName.toLowerCase().includes(searchTerm.toLowerCase())))
                            ).map((video) => {
                                const thumbnailUrl = videoThumbnails[video._id] || thumbnail;
                                return (
                                                    
                                                    <div className={styles.video_wrapper} key={video._id} onClick={() => handlePlayClick(video)} >
                                        {/* Video Date Section */}
                                        <div className={styles.data_icons_container}>
                                        <div className={styles.date_cont}>
                                            <div className={styles.leftContent}>
                                            <p className={styles.playerName}>{extractPlayerName(video)}</p>
                                            <p className={styles.videoTitle}>{truncateTitle(extractTitle(video.game[0]?.path))}</p>
                                                
                                            </div>
                                            <p className={styles.videoDate}>{dataFormatter(video.updatedAt)}</p>
                                        </div>

                                        </div>

                                        {/* Video Box Section */}
                                        <div className={styles.video_box}>
                                            <div
                                                className={styles.video_cont}
                                                style={{ backgroundImage: `url(${thumbnailUrl})` }}
                                            >
                                                <Button onClick={() => handlePlayClick(video)}>Ready for Feedback</Button>
                                            </div>
                                            <div className={styles.icons}>
                                                <div
                                                    className={styles.play_icon}
                                                >
                                                    <FontAwesomeIcon icon={faCirclePlay} />
                                                    <span>Play</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p>No videos available</p>
                        )}
                    </div>}
                        
                    <div>{data?.filter(video => 
                                        ((searchTerm.length === 0) || video?.tags.some(tag => tag.tagName.toLowerCase().includes(searchTerm.toLowerCase())))
                                    ).length === 0 &&  <p className={styles.noVideosFoundForTag}> No matching videos found for this tag name</p>}
                                </div>

                {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {COUNT > 0 && (
                        <Stack spacing={2} sx={{ marginBlock: "2rem", alignItems: "center" }}>
                            <Pagination
                                count={COUNT}
                                page={page}
                                onChange={handleChange}
                                size="large"
                                color="primary"
                            />
                        </Stack>
                    )}
                </div> */}
            </div>

            {/* {selectedVideo && (
                <FeedbackWrite
                    videoId={selectedVideo._id}
                    closeModal={closeModal}
                />
            )} */}

            {/* <Dialog
                open={isModalOpen}
                onClose={closeModal}
                maxWidth={false} // Disable default maxWidth
                fullScreen // Makes the dialog cover the full window
                sx={{
                    '& .MuiDialog-paper': {
                        margin: 0, // Remove default margin
                        width: '100%', // Full width
                        height: '100%', // Full height
                        maxWidth: '100%', // Prevent max-width limitation
                    },
                }}
            >
                <DialogContent>

                </DialogContent>
            </Dialog> */}

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </div>
    );
};

export default Pending;






